<template>
<div class="risk">
    <div class="header">
        <!-- <div id="avatar">
            <img class="avatar" src="/static/Policy Center ICON logo-RGB.svg">
        </div> -->
        <div id="title-box">
                <a href="https://www.2020mom.org/">
                    <img class="title-box pcmmh" src="/static/Policy-Center-HORIZ-logo-RGB-full.svg">
                </a>
                <a href="https://plumorganics.com/">
                    <img class="title-box plum" src="/static/plum-high-res-logo.png">
                </a>
        </div>
        <div id="socialLinks">
            <a href="https://www.youtube.com/@PolicyCenterMMH"><img class="socialLinks" src="/static/youtube.svg" /></a>
            <a href="https://twitter.com/PolicyCenterMMH"><img class="socialLinks" src="/static/X_logo_2023_original.svg" /></a>
            <a href="https://github.com/2020Mom/mmh-risk-factors"><img class="socialLinks" src="/static/github.svg" /></a>
            <a href="https://www.linkedin.com/company/pcmmh/"><img class="socialLinks" src="/static/linkedin.svg" /></a>
        </div>
        <div>
            <button id="explore" @click="explore">
                <!-- <i class="bi bi-list explore" style="font-size:min(10vw,7em);line-height:1;"></i> -->
                <img class="explore" src="/static/list.svg" />
            </button>
        </div>
    </div>
    <div id="docs" v-show="rhizomeOn">
        <div id="docContent">
            <div id="overview" class="overview">
                <h3 style="padding-top:0vw;">Overview:<br>Maternal Mental Health Risk Factors</h3><br>

                <p>Risk Factors Scores for Perinatal Mental Health are derived by an algorithm incorporating:

                19 socio-demographic factors that are shown to be associated with a greater risk of perinatal mental health disorders, and

                3 factors which are direct measures of need.<br><br>

                US regions with compounded perinatal mental health risk factors may be in greater need of high-quality, person-centered perinatal mental health support.</p>
                <br>
            </div>
            <div id="rfscores" class="rfscores">
                <h3>Risk Factors Score</h3><br>
                <p>
                    The maternal health risk factors are divided into three tiers, using a scale which allocates:
                    up to (3) points per Tier I qualifier,
                    up to (2) points per Tier II qualifier, and
                    up to (1) point per Tier III qualifier.
                </p><br>
                <p>
                    Additionally, there are several Direct Measures of Need, which are allocated up to (3) points per qualifier.
                    The maximum possible point total is 45.
                </p>
            </div>
            <div id="cprovs" class="cprovs">
                <h3>PMH Certified Providers</h3>
                <br>
                <p>A bubble diagram shows the concentration of perinatal mental health providers including, perinatal mental health certified providers, prescribers trained to treat maternal mental health disorders and Community Based Organizations offering mental health services to perinatal populations.</p><br>
            </div>
            <div id="pratio" class="pratio">
                <h3 style="padding-top:0vw;">Provider Ratio</h3><br>
                <p>
                    The Provider Ratio is measured as the ratio of providers in a given county per one thousand (1k) estimated annual births in that county.
                </p>
            </div>
            <div id="psgap" class="psgap">
                <h3 style="padding-top:0vw;">Provider Shortage Gap</h3><br>
                <p>
                    The Provider Shortage Gap is an estimate of the number of PMH-Certified providers required in order to meet the service level needs of a given county.  
                </p><br>
                <p>
                    The target level Provider Ratio is 5.0 providers per 1,000 annual births.  Therefore, a county with a provider ratio of 4.0 is estimated to have a 20% shortage of providers, requiring an increase of 25% from the current level in order to meet the local service level need.
                </p>
            </div>
        </div>
        <div class="docButton">
            <ul>
                <li v-for="detail in details.details" :key="detail.name" >
                    <button :id="detail.id">{{ detail.name }}</button>
                </li>
            </ul>
        </div>
    </div>
    <div class="introduction">
        <h2>2023 U.S. Maternal Mental Health Risk and Resources Maps</h2>
        <div class="textCols">
            <p>Click on a county to reveal the risk factors score and the Provider coverage detail.</p>
            <!-- <h3>Participate in the 'Pin'-tition</h3>
            <p>To add a pin to the county you want to see gain additional resources:</p>
            <ul>
                <li>1.  First, tap the clear button to be sure no counties are selected</li>
                <li>2.  Click on <u>one</u> county (your screen will zoom in on that county)</li>
                <li>3.  Click on the “Add a Pin to the Map” button under the map (you may need to scroll to see the button)</li>
                <li>4.  Add your name and email to the pop-up box</li>
                <li>5.  You will see a confirmation that your pin was accepted, and <p style="color:#631a6c;font-size:1em;font-weight:bold;">You can expect your pin will be added within 24 hours*</p></li>
            </ul>
            <p id="invite">We invite you to come back to see where pins are being dropped throughout the U.S. and to invite others to drop a pin too!</p> -->
            <h3>Mothers and Families in Need find immediate support</h3>
            <h3>U.S. Maternal Mental Health Hotline</h3>
            <h3>Text or Call 1-833-943-5746</h3>
        </div>
    </div>
    <div class="feature">
        <div class="map">
            <div id="pintition-form">
                <div id="introText">
                    <h2>Add a Pin to</h2><h2>the 'Pin'-tition!</h2>
                    <p>Enter your contact information to request increased provider support for:</p>
                        {{ clist[0] }}
                </div>
                <div id="enterInfo">
                    <input id="fn" type="text" placeholder="First Name">
                    <input id="mn" type="text" placeholder="Middle">
                    <input id="ln" type="text" placeholder="Last Name">
                    <!-- <input id="on" type="text" placeholder="Organization Name"> -->
                    <!-- <input id="cy" type="text" placeholder="County">
                    <input id="st" type="text" placeholder="State"> -->
                    <input id="em" type="text" placeholder="Email">
                </div>
                <div id="verifySubmit">
                    <button id="verify">Verify Email</button>
                </div>
                <div id="verifyInput">
                    <input id="code" type="text" placeholder="6-digit Verification Code">
                </div>
                <div id="formSubmit">
                    <!-- <input type="submit"> -->
                    <button id="pintSubmit">Submit</button>
                    <button id="pintClose">Close</button>
                </div>
            </div>
            <div id="verifyInstructions">
                <p>A 6-digit verification code was sent to the provided email address. Enter the code in the 'Verification Code' input above, then tap 'Submit'</p>
            </div>

            <div id="pintition-info">
                <h2>Sign a Pintition!</h2>
                <p>Enter your contact information to sign the Pintition for increased provider support for:</p>
                    {{ mrkName }}
                <input type="text" placeholder="First Name">
                <input type="text" placeholder="Last Name">
                <input type="text" placeholder="Organization Name">
                <input type="text" placeholder="County">
                <input type="text" placeholder="State">
                <input type="text" placeholder="Email"><br><br>
                <button id="pintSign">Sign</button>
                <button id="pintInfo">Cancel</button>
            </div>
            <div class="choropleth">
                <svg id="choropleth"></svg>
            </div>
        </div>
        <div class="controls">
            <div class="dials">
                <div class="boxTop">
                    <p><code><span id="s"></span></code></p>
                    <p><code><span id="t"></span></code></p>
                </div>

                <div class="radio">
                    <div class="input1">
                        <input id="input1" class=".checkbox" type="checkbox" v-model="showProviders" /><label> Perinatal Mental Health Providers</label>
                    </div>
                    <div class="input2">
                        <input id="input2" class=".checkbox" type="checkbox" v-model="showGap" /><label> Provider Shortage Gap</label>
                    </div>
                    <!-- <div class="input3">
                        <input id="input3" class=".checkbox" type="checkbox" v-model="showPintitions" /><label> 'Pin'-titions</label>
                    </div> -->
                </div>
                <!-- <div id="mapPin"> -->
                    <!-- <button id="startPintition" class="button" type="button">Add a Pin to the Map</button> -->
                <!-- <div id="mapPinheader"> -->
                    <!-- <svg id="grabPin"></svg> -->
                    <!-- <img id="grabPin" class="markers" title="Drag and Drop the Pin to Start a Pintition!" style="background-color: #2f7f34;border-radius: 1vw;" src="/static/pin-square.svg"> -->
                <!-- </div> -->
                <!-- </div> -->
                <!-- <div class="selectors">
                    <select v-model="featuredColor">
                        <option v-for="item in colornames">
                            {{  item }}
                        </option>
                    </select>
                    <p>Levels</p>
                    <select v-model="levels">
                        <option v-for="item in 9" :key="item">
                            {{  item }}
                        </option>
                    </select>
                </div> -->
                <div class="selectAgg">
                    <button id="clear" class="button" type="button">CLEAR</button>
                    <button id="reset" class="button" type="button">RESET</button>
                </div>
            </div>
        </div>
        <div class="hoverData">
            <div class="boxLeft">
                <p><code><span id="s2"></span></code></p>
                <p><code><span id="t2"></span></code></p>
                <p><code><span id="u"></span></code></p>
                <p><code><span id="v"></span></code></p>
                <!-- <p><code><span id="w"></span></code></p> -->
                <p><code><span id="x"></span></code></p>
                <p><code><span id="y"></span></code></p>
                <p><code><span id="z"></span></code></p>
            </div>
            <div class="boxRight">
                <!-- <p><code><span>County</span></code></p>
                <p><code><span>State</span></code></p> -->
                <!-- <p><code><span id="s"></span><span id="t"></span></code></p> -->
                <p><code><span>Risk Factors Score</span></code></p>
                <p><code><span>PMH Providers</span></code></p>
                <!-- <p><code><span>Female Pop. Age 15-44</span></code></p> -->
                <p><code><span>Est. Annual Births</span></code></p>
                <p><code><span>Provider Ratio</span></code></p>          
                <p><code><span>Provider Shortage Gap</span></code></p>
                <!-- <p><code><span>Total Need Index</span></code></p> -->
            </div>
            <div class="aggregate">
                <p><code><span id="s1"></span></code></p>
                <p><code><span id="t1"></span></code></p>
                <p><code><span id="u1"></span></code></p>
                <p><code><span id="v1"></span></code></p>
                <!-- <p><code><span id="w1"></span></code></p> -->
                <p><code><span id="x1"></span></code></p>
                <p><code><span id="y1"></span></code></p>
                <p><code><span id="z1"></span></code></p>
            </div>
            <div class="list">
                <p id="selectionPrompt">Selected Counties:</p>
                <div id="county-state">
                    <li class="counties" v-for="item in clist">
                        {{ item }}
                    </li>
                </div>
            </div>
        </div>
        <div id="shareBlock" v-show="modalOn">
                <div class="modal-header">
                    <div><span class="modal-title">Support Maternal Mental Health by Sharing the Map!</span></div>
                </div>
                <div class="modal-body">
                    <div class="modal-icons" v-for="account in socialMedia" :key="account.id">
                            <a :id=account.name :class=account.class :href=account.url target="_blank"
                                :data-text=account.text :data-size=account.size
                                :data-hashtags=account.hashtags :data-url=account.srcUrl
                                >
                                <img svg-inline :src=account.logo class="share-img">
                            </a>
                    </div>
                    <div class="modal-icons">
                        <button class="share-btn" type="button" @click="copyText">
                            <img title="Copy Link" svg-inline class="share-img" src="/static/link.svg" />
                        </button>
                    </div>
                </div>
        </div>
    </div>
    <!-- <footer id="footer">
        <p>OUR MISSION<br>
           Closing gaps in maternal mental health care.<br>
           Copyright © 2011-2023 Policy Center for Maternal Mental Health. All Rights Reserved.<br>
           The Policy Center for Maternal Mental Health is a DBA of 2020 Mom, a tax-exempt social change organization 501(c)(3).
           <br>Tax ID: 45-5009704 
        </p>
    </footer> -->
    </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, onUpdated, onBeforeUpdate, watch, watchEffect } from 'vue';
import * as d3 from 'd3';
import * as tjsClient from "topojson-client";
import usAlbers_states from '../data/geojson/final_score_20m-topo-simple-state-merge.json';
import scoreSummary from '../data/json/final_score.json';
import details from './modules/details.mjs';

import pinSquare from '../assets/pin-square.svg'
import Twitter from '../assets/X_logo_2023_original.svg'
import FaceBook from '../assets/social-icons/f_logo_RGB-Blue_100.png'
import LinkedIn from '../assets/LinkedIn-Logos/LinkedIn-Logos/LI-In-Bug.png'

import Twitter_nc from '/static/twitter.svg'
import LinkedIn_nc from '/static/linkedin.svg'
import Youtube_nc from '/static/youtube.svg'

// import mLocs from '../data/json/markerTest.json'

const url = "https://www.clueresearch.com/api/pins?format=json"
const purl = "https://www.clueresearch.com/api/pintitions/X"
const purl2 = "https://www.clueresearch.com/api/pintitions/"

// const kvurl = "https://serverless-api.childerino.workers.dev/"
// const corsHeaders = {
//     'Access-Control-Allow-Headers': '*',
//     'Access-Control-Allow-Origin' : '*'
// }

// const response = await fetch(url, {
//     method: "GET", // *GET, POST, PUT, DELETE, etc.
//     mode: "cors", // no-cors, *cors, same-origin
//     // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
//     // credentials: "same-origin", // include, *same-origin, omit
//     // headers: corsHeaders,
//     // redirect: "follow", // manual, *follow, error
//     // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//     // body: JSON.stringify(data), // body data type must match "Content-Type" header
//   });

const kval = ref(+1)
const xval = ref(+0)
const yval = ref(+0)

function returnTranslate() {
                let x = -15/kval.value
                let y = -15/kval.value
                return "translate("+x+" "+y+")"
            }

async function clicked1(event, d) {
    // console.log(d.xPos)
    // console.log(d.yPos)
    mrkName.value = d.Name
    let x0 = d.xPos - 15;
    let y0 = d.yPos - 15 ;
    let x1 = d.xPos + 15; 
    let y1 = d.yPos + 15;
    event.stopPropagation();
    svg.transition().duration(750).call(
    zoom.transform,
    d3.zoomIdentity
    .translate(width / 2, height / 2)
    .scale(Math.min(10))
    // .scale(Math.min(10, 0.9 / Math.max((x1 - x0) / width, (y1 - y0) / height))),
    .translate(-(d.xPos), -(d.yPos)),
    d3.pointer(event, svg.node()),
    );
    // readPintition()
}

// const switcher = ref(+0)

// function colorswitch() {
//     if (switcher.value == 0) {
//         switcher.value = 1
//     } else {
//         switcher.value = 0
//     }
// }

let markerCol = "#2f7f34"

// watch(switcher, async() => {
//     if (switcher.value == 0) {
//         markerCol = "blue"
//     } else if (switcher.value == 1) {
//         markerCol = "red"
//     } else {}
// })


    // setInterval(() => {testResponse()},5000);

    // setInterval(testResponse(),1000)
    // let bytes = 0
    // async function kvStream () {
    //     const response = await fetch(kvurl)
    //     // console.log(response.body)
    //     // let { readable, writable } = new TransformStream();
    //             const reader = response.body.getReader();
    //             let charsReceived = 0;
    //             reader.read().then(function processText({ done, value }) {
    //                 // When no more data needs to be consumed, close the stream
    //                 if (done) {
    //                     console.log("Stream complete");
    //                     // para.textContent = value;
    //                     return;
    //                 }
    //                 // Enqueue the next data chunk into our target stream
    //                 charsReceived += value.length;
    //                 const chunk = value;
    //                 var string = new TextDecoder().decode(chunk)
    //                 console.log(`Received ${charsReceived} characters so far. Current chunk = ${string}`)

    //                 // result += chunk;
    //                 return reader.read().then(processText);
    //             });
    //     };

    // response.body.pipeTo(readable);
    // console.log(response.body)

    // return new Response(readable, response);
    // for await (const chunk of response.body) {
    //     console.log(chunk)
    //     bytes += chunk.length;
    //     console.log(`Chunk: ${chunk}. Read ${bytes} characters.`);
    // }
                                // .then((response) => {
                                //     const reader = response.body.getReader();
                                //     return new ReadableStream({
                                //                     start(controller) {
                                //                         return pump();
                                //                         function pump() {
                                //                         return reader.read().then(({ done, value }) => {
                                //                             // When no more data needs to be consumed, close the stream
                                //                             if (done) {
                                //                             controller.close();
                                //                             return;
                                //                             }
                                //                             // Enqueue the next data chunk into our target stream
                                //                             controller.enqueue(value);
                                //                             console.log(value)
                                //                             return pump();
                                //                         });
                                //                         }
                                //                     },
                                //                 });
                                //     })
                                // .then((stream) => new Response(stream))
                                // .then((stream) => console.log(stream))
    
    // console.log(response2)
    // console.log(signers)
    // const resjson2 = await reponse2.json()
    // const bob = await resjson2.results

// kvStream()
// setInterval(testResponse(), 5000)

// const robj = response.json()
// console.log(robj.results)
// const rj = await response.json();
// console.log(rj)

const modalOn = ref(true);
let curPath = ref("https://www.mmhmap.com")

const socialMedia = [
    { 
        "name": "Twitter Share Button",
        "url": "https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.mmhmap.com%2F",
        "srcUrl": window.location.href,
        "logo": Twitter,
        "size": "large",
        "text": "2023 County Risk Factors Scores: Perinatal Mental Health",
        "hashtags": ['#MMHMap']
    },
    {
        "name": "LinkedIn Share Button",
        "url": "https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.mmhmap.com%2F",
        "srcUrl": "https://www.mmhmap.com",
        "data-url": "https://www.mmhmap.com",
        "logo": LinkedIn,

    },
    {
        "name": "Facebook Share Button",
        "url": "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.mmhmap.com%2F&amp;src=sdkpreparse",
        "class": "fb-xfbml-parse-ignore",
        "logo": FaceBook
    },
]

function copyText() {
    // let ctext = document.getElementById('urlInput')
    // console.log(ctext.value)
    let cb = navigator.clipboard;
    cb.writeText(curPath.value);
}
// "#397dc9", #631a6c
const pcHex1 = ["#1f0970","#ff5a5a","#f68e2d","#ffc846"]
// const pcHex2 = [pcHex1.reverse()]
const plumHex1 = ["#631a6c","#8a5391","#b18db5","#c4a9c8","#ebe2ed"]
const plumHex2 = ["#ebe2ed","#8a5391","#631a6c"]

const pcRgbL = []
const plumRGB = []
pcHex1.forEach((i) => pcRgbL.push(d3.rgb(i)))
plumHex2.forEach((i) => plumRGB.push(d3.rgb(i)))
const pcRgbD = []
const pcHex3 = pcHex1.reverse()
pcHex3.forEach((i) => pcRgbD.push(d3.rgb(i)))

// console.log(plumHex1)

const pcColorIntD =
    d3.scaleLinear()
        .domain([0.2,0.4,0.6,1])
        // .domain([0.2,0.6,0.8,1])
        // .domain([1,0.8,0.6,0.4,0.2])
        .range(pcRgbD)
        .interpolate(d3.interpolateRgb)

const pcColorIntL =
    d3.scaleLinear()
        // .domain([0.2,0.4,0.6,0.8,1])
        .domain([0.2,0.6,0.8,1])
        // .domain([1,0.8,0.6,0.4,0.2])
        .range(pcRgbL)
        .interpolate(d3.interpolateRgb)

const plumColors =
    d3.scaleLinear()
        // .domain([0.2,0.4,0.6,0.8,1])
        .domain([0.2,0.6,0.8,1])
        // .domain([1,0.8,0.6,0.4,0.2])
        .range(plumRGB)
        .interpolate(d3.interpolateRgb)

// *****************
const rhizomeOn = ref(false);

// const datafetch = ref(mLocs._value)
// console.log(datafetch)

const filename = scoreSummary
const dataset = reactive(filename)

// DEFINE VARIABLES
const width = +975
const height = +610
const insetLeft = 0.5 // inset left edge of bar
const insetRight = 0.5 // inset right edge of bar
const marginTop = 0 // top margin, in pixels
const marginRight = 0 // right margin, in pixels
const marginBottom = 0 // bottom margin, in pixels
const marginLeft = 0 // left margin, in pixels
const position = 0.6
const fill = "white" // fill color for outline
const stroke = "white" // stroke color for borders
const strokeLinecap = "round" // stroke line cap for borders
const strokeLinejoin = "round" // stroke line join for borders
const strokeWidth = ref(+1)// stroke width for borders
const strokeOpacity = ref(+1)
const xMax = ref()
const xMin = ref()
const xDomain = reactive([])
const xRange = [marginTop, height - marginBottom]

const colorscales = [   pcColorIntD,
                        pcColorIntL,
                        plumColors,
                        d3.interpolateMagma,
                        d3.interpolatePlasma,
                        d3.interpolateRainbow,
                    //  d3.interpolateSpectral,
                    //  d3.interpolateTurbo,
                        d3.interpolateInferno,
                        d3.interpolateCividis,
                        d3.interpolateViridis,
                        d3.interpolateReds,
                        d3.interpolateOranges,
                        d3.interpolateYlOrBr,
                        d3.interpolateGreens,
                        d3.interpolateBlues,
                        d3.interpolateYlGnBu,
                        d3.interpolatePurples,
                        d3.interpolateGreys]
const colornames = [
                    "PCMMH-Dark",
                    "PCMMH-Light",
                    "Plum-Colors",
                    "Magma",
                    "Plasma",
                    "Rainbow",
                    // "Spectral",
                    // "Turbo",
                    "Inferno",
                    "Cividis",
                    "Viridis",
                    "Reds",
                    "Oranges",
                    "YlOrBr",
                    "Greens",
                    "Blues",
                    "YlGnBu",
                    "Purples",
                    "Greys"]

const featuredColor = ref("Plum-Colors")
const magicNumber = ref(2)
const colorR = ref(colorscales[magicNumber.value])

const glist = ref([])
const clist = ref([])
const rfact = ref(0)
const cpro = ref(0)
const eab = ref(0)
const shortageGap = ref(0)
const levels = ref(+5)
const showProviders = ref(true)
const showGap = ref(false)
const showPintitions = ref(true)

const mrkName = ref()
const pinCoords = ref([])
const pinCent = ref([])
// CREATE MAP TOPOLOGIES
const counties = tjsClient.feature(usAlbers_states, usAlbers_states.objects.counties)
const features = counties
const countymesh = tjsClient.mesh(usAlbers_states, usAlbers_states.objects.counties, (a, b) => a !== b)
const states = tjsClient.feature(usAlbers_states, usAlbers_states.objects.states)
const statemesh = tjsClient.mesh(usAlbers_states, usAlbers_states.objects.states, (a, b) => a !== b)

const expnt = ref(1)
const curVariable = ref(["PRFS"])

const curScale = d3.scalePow().exponent(expnt.value)
const id = d => d.FIPS
const featureId = d => d.id
const value = d => d.PRFS
const N = d3.map(dataset, id)
const V = d3.map(dataset, value).map(d => d == null ? NaN : +d)
const Im = new d3.InternMap(N.map((id, i) => [id, i]))
const If = d3.map(features.features, featureId)

const xType = curScale
const xScale = xType
xMax.value = d3.max(V)
xMin.value = Math.min(d3.min(V),0)
xDomain.values = [xMin.value,xMax.value]
const nScaleX = xScale.domain(xDomain.values)
const linScaleStep = xMax.value/levels.value
const linSteps = []
linSteps.push(0)
Array(Number(levels.value)).fill().map((d,i) => (i+1)*linScaleStep).forEach( (d) => linSteps.push(d))
const colorScale = colorR.value
const linColorScale= []
linSteps.forEach(i => linColorScale.push(colorScale(nScaleX(i))))

const curScaleSteps = []
Array(Number(levels.value)).fill().map((d,i) => (i+1)*linScaleStep).forEach( (d) => curScaleSteps.push(d))

const path = d3.geoPath();

// const logo = d3.select("pcLogoText")
// const svgDefs = logo.append('defs');
//     const brks = levels.value*10
//     const blcks = Array.from(Array(brks).keys())
//     const mainGradient = svgDefs.append('linearGradient')
//         .attr('id', 'mainGradient')
//         .attr('x1', '0%')
//         .attr('x2', '0%')
//         .attr('y1', '100%')
//         .attr('y2', '0%');;
    
//     for (var b in blcks) {
//         mainGradient.append('stop')
//         .attr('id', d => b)
//         .data([b*(xMax.value/brks)])
//         .attr('stop-color', (d) => pcColorIntD(nScaleX(d)))
//         .attr('offset', (d) => d/xMax.value)
//     }

// console.log(mainGradient)

const oviewOn = ref(true)
const rfscoresOn = ref(false)
const cprovsOn = ref(false)
const pratioOn = ref(false)
const psgapOn = ref(false)

// SET UP VUE.JS WATCH FUNCTION ON CURRENT YEAR SELECTOR
watch(featuredColor, async() => {
    magicNumber.value = colornames.indexOf(featuredColor.value)
    colorR.value = colorscales[magicNumber.value]
    updateFill()
    })

watch(levels, async() => {
    updateFill()
    })

watch(kval, async() => {
    labelShow()
    // if (kval.value < 5) {
    //     let pinBack = d3.select("#pinBack")
    //     let pinButt = d3.select("#pinButt")
    //     pinBack.attr("visibility", "hidden")
    //     pinButt.attr("visibility", "hidden")
    // } else {}
})

watch(showProviders, async() => {
    const provx = d3.select('#providers').selectAll("circle")
    const provLab = d3.select('#providerLabel')
    const circleScale = d3.select('#circleScale').selectAll("text")
    const lgCircle = d3.select('#largeCircle')
    const smCircle = d3.select('#smallCircle')
    const circleBg = d3.select('#circlebg')
    if (showProviders.value == true) {
        provx.attr("visibility", "visible")
        provLab.attr("visibility", "visible")
        circleScale.attr("visibility", "visible")
        lgCircle.attr("visibility", "visible")
        smCircle.attr("visibility", "visible")
        circleBg.attr("visibility", "visible")
        showGap.value = false
    } else if (showProviders.value == false) {
        provx.attr("visibility", "hidden")
        provLab.attr("visibility", "hidden")
        lgCircle.attr("visibility", "hidden")
        smCircle.attr("visibility", "hidden")
        circleScale.attr("visibility", "hidden")
        circleBg.attr("visibility", "hidden")
        if (showGap.value == true) {
            lgCircle.attr("visibility", "visible")
            smCircle.attr("visibility", "visible")
            circleScale.attr("visibility", "visible")
            circleBg.attr("visibility", "visible")
        } else if (showGap.value == false) {
            lgCircle.attr("visibility", "hidden")
            smCircle.attr("visibility", "hidden")
            circleScale.attr("visibility", "hidden")
            circleBg.attr("visibility", "hidden")
        }
    } else {
        console.log("error watch(showProviders)")
    }
})

watch(showGap, async() => {
    const gapx = d3.select('#gap').selectAll("circle")
    const gapLab = d3.select('#gapLabel')
    const circleScale = d3.select('#circleScale').selectAll("text")
    const lgCircle = d3.select('#largeCircle')
    const smCircle = d3.select('#smallCircle')
    const circleBg = d3.select('#circlebg')
    if (showGap.value == true) {
        gapx.attr("visibility", "visible")
        gapLab.attr("visibility", "visible")
        circleScale.attr("visibility", "visible")
        lgCircle.attr("visibility", "visible")
        smCircle.attr("visibility", "visible")
        circleBg.attr("visibility", "visible")
        showProviders.value = false
    } else if (showGap.value == false) {
        gapx.attr("visibility", "hidden")
        gapLab.attr("visibility", "hidden")
        if (showProviders.value == true) {
            lgCircle.attr("visibility", "visible")
            smCircle.attr("visibility", "visible")
            circleScale.attr("visibility", "visible")
            circleBg.attr("visibility", "visible")
        } else if (showProviders.value == false) {
            lgCircle.attr("visibility", "hidden")
            smCircle.attr("visibility", "hidden")
            circleScale.attr("visibility", "hidden")
            circleBg.attr("visibility", "hidden")
        }
    } else {
        console.log("error watch(showGap)")
    }
})

watch(showPintitions, async() => {
    const pintLabel = d3.selectAll('#markerKey')
    const pintText = pintLabel.selectAll('text')
    const pintMarkers = d3.selectAll('#markers')
    const pinBackG = d3.select('#pinbg')
    if (showPintitions.value == true) {
        // console.log(pintitions)
        pintLabel.attr("visibility", "visible")
        pintText.attr("visibility", "visible")
        pintMarkers.attr("visibility", "visible")
        pinBackG.attr("visibility", "visible")
        // showProviders.value = false
    } else if (showPintitions.value == false) {
        pintLabel.attr("visibility", "hidden")
        pintText.attr("visibility", "hidden")
        pintMarkers.attr("visibility", "hidden")
        pinBackG.attr("visibility", "hidden")
        // if (showProviders.value == true) {
        //     lgCircle.attr("visibility", "visible")
        //     smCircle.attr("visibility", "visible")
        //     circleScale.attr("visibility", "visible")
        // } else if (showProviders.value == false) {
        //     lgCircle.attr("visibility", "hidden")
        //     smCircle.attr("visibility", "hidden")
        //     circleScale.attr("visibility", "hidden")
        // }
    } else {
        console.log("error watch(showbrth)")
    }
})

watch (oviewOn, async() => {
    const ov = d3.select("#overview")
    const rs = d3.select("#rfscores")
    const cp = d3.select("#cprovs")
    const pr = d3.select("#pratio")
    const sg = d3.select("#psgap")
    if (oviewOn.value == true) {
            ov.style("display", "block")
            rs.style("display", "none")
            rfscoresOn.value = false
            cp.style("display", "none")
            cprovsOn.value = false
            pr.style("display", "none")
            pratioOn.value = false
            sg.style("display", "none")
            psgapOn.value = false
    } else if (oviewOn.value == false) {
            ov.style("display", "none")
    } else {
        console.log("error oviewOn watch()")
    }
})

watch (rfscoresOn, async() => {
    const ov = d3.select("#overview")
    const rs = d3.select("#rfscores")
    const cp = d3.select("#cprovs")
    const pr = d3.select("#pratio")
    const sg = d3.select("#psgap")
    if (rfscoresOn.value == true) {
            rs.style("display", "block")
            ov.style("display", "none")
            oviewOn.value = false
            cp.style("display", "none")
            cprovsOn.value = false
            pr.style("display", "none")
            pratioOn.value = false
            sg.style("display", "none")
            psgapOn.value = false
    } else if (rfscoresOn.value == false) {
            rs.style("display", "none")
    } else {
        console.log("error rfscoresOn watch()")
    }
})

watch (cprovsOn, async() => {
    const ov = d3.select("#overview")
    const rs = d3.select("#rfscores")
    const cp = d3.select("#cprovs")
    const pr = d3.select("#pratio")
    const sg = d3.select("#psgap")
    if (cprovsOn.value == true) {
        // console.log("true")
            cp.style("display", "block")
            rs.style("display", "none")
            rfscoresOn.value = false
            ov.style("display", "none")
            oviewOn.value = false
            pr.style("display", "none")
            pratioOn.value = false
            sg.style("display", "none")
            psgapOn.value = false
    } else if (cprovsOn.value == false) {
            cp.style("display", "none")
    } else {
        console.log("error cprovsOn watch()")
    }
})

watch (pratioOn, async() => {
    const ov = d3.select("#overview")
    const rs = d3.select("#rfscores")
    const cp = d3.select("#cprovs")
    const pr = d3.select("#pratio")
    const sg = d3.select("#psgap")
    if (pratioOn.value == true) {
            pr.style("display", "block")
            rs.style("display", "none")
            rfscoresOn.value = false
            ov.style("display", "none")
            oviewOn.value = false
            cp.style("display", "none")
            cprovsOn.value = false
            sg.style("display", "none")
            psgapOn.value = false
    } else if (pratioOn.value == false) {
            pr.style("display", "none")
    } else {
        console.log("error pratioOn watch()")
    }
})

watch (psgapOn, async() => {
    const ov = d3.select("#overview")
    const rs = d3.select("#rfscores")
    const cp = d3.select("#cprovs")
    const pr = d3.select("#pratio")
    const sg = d3.select("#psgap")
    if (psgapOn.value == true) {
            sg.style("display", "block")
            rs.style("display", "none")
            rfscoresOn.value = false
            ov.style("display", "none")
            oviewOn.value = false
            cp.style("display", "none")
            cprovsOn.value = false
            pr.style("display", "none")
            pratioOn.value = false
    } else if (psgapOn.value == false) {
            sg.style("display", "none")
    } else {
        console.log("error pratioOn watch()")
    }
})

watch(glist, async() => {
    if (glist.value == 0 ) {
        shortageGap.value = 0
        eab.value = 0
        rfact.value = 0
        cpro.value = 0
    } else {}
})

// DEFINE FUNCTIONS
function onlyUnique(d,i,self) {
    return self.indexOf(d) === i;
}

async function labelShow() {
            const countyLabels = document.getElementsByClassName('labels')
            const stateLabels = document.getElementsByClassName('labels2')
            if (kval.value > 6) {
                countyLabels[0].style.visibility = "visible"
                countyLabels[0].style.fillOpacity = kval.value/12+.125
                stateLabels[0].style.visibility = "visible"
                stateLabels[0].style.fillOpacity = (1 - kval.value/12)*2
            // } else if (kval.value > 6.5) {
            //     countyLabels[0].style.visibility = "visible"
            //     stateLabels[0].style.visibility = "visible"
            //     stateLabels[0].style.fillOpacity = 1-kval.value/10
            } else if (kval.value > 2) {
                countyLabels[0].style.visibility = "hidden"
                stateLabels[0].style.visibility = "visible"
                stateLabels[0].style.fillOpacity = kval.value/6
            } else {
                countyLabels[0].style.visibility = "hidden"
                stateLabels[0].style.visibility = "hidden"
            }
}

function explore() {
    if (rhizomeOn.value == false) {
        // console.log(rhizomeOn.value)
        rhizomeOn.value = true
        // modalOn.value = false
        // viewsOn.value = false
        // document.getElementById('docs').className = "fallingwater-btn-active"
        // document.getElementById('connect-btn').className = "fallingwater-btn"
        // document.getElementById('share-btn').className = 'fallingwater-btn'
        // document.getElementById('expand-btn').className = 'fallingwater-btn'
        } else if (rhizomeOn.value == true) {
            // console.log(rhizomeOn.value)
            rhizomeOn.value = false
            // document.getElementById('explore-btn').className = 'fallingwater-btn'
            }
}

const svg = d3.select("#choropleth")
const cntys = svg.select("#counties")
const g = svg.append("g")

async function updateFill() {
    const V = d3.map(dataset, value).map(d => d == null ? NaN : +d)
    xMax.value = d3.max(V)
    xMin.value = Math.min(d3.min(V),0)
    xDomain.values = ref([Math.max(xMin.value,0),xMax.value])
    // console.log(xDomain.values)
    const linScaleStep = xMax.value/levels.value
    const xType = curScale
    const xScale = xType
    const nScaleX = xScale.domain(xDomain.values)
    const colors = colorR.value

    // const linSteps = Array(levels.value).fill().map((d,i) => (i+1)*linScaleStep)
    const linSteps = []
    linSteps.push(0)
    Array(Number(levels.value)).fill().map((d,i) => (i+1)*linScaleStep).forEach( (d) => linSteps.push(d))
    const linColorScale= []
    linSteps.forEach(i => linColorScale.push(colors(nScaleX(i))))
    // // console.log(linColorScale)

    const curScaleSteps = Array(Number(levels.value)).fill().map((d,i) => xMax.value*nScaleX((i+1)*linScaleStep))
    // const linSteps = Array(levels.value).fill().map((d,i) => (i+1)*linScaleStep)

    const curColorScale = []
    curScaleSteps.forEach(i => curColorScale.push(colors(nScaleX(i))))
    function curScaleColorStep(d) {
        // console.log(d)
        if (d == 0) {
            return "#181818"
        } else if (d <= curScaleSteps[0]) {
            return curColorScale[0]
        } else if (d <= curScaleSteps[1]) {
            return curColorScale[1]
        } else if (d <= curScaleSteps[2]) {
            return curColorScale[2]
        } else if (d <= curScaleSteps[3]) {
            return curColorScale[3]
        } else if (d <= curScaleSteps[4]) {
            return curColorScale[4]
        } else if (d <= curScaleSteps[5]) {
            return curColorScale[5]
        } else if (d <= curScaleSteps[6]) {
            return curColorScale[6]
        } else if (d <= curScaleSteps[7]) {
            return curColorScale[7]
        } else {
            return curColorScale[8]}
    }
    const svg = d3.select("#choropleth")
    const cntys = svg.select("#counties")
    const g = svg.append("g")

    cntys.selectAll("path").attr("fill", (d,i) =>  curScaleColorStep(V[Im.get(If[i])]))

    svg.select('#riskbg')
            .attr("y", () => height - height*(0.04*(curScaleSteps.length+2.2)) - 0)
            .attr("height", height*(0.04*(curScaleSteps.length+2))+0)

    svg.selectAll("#scaleBoxes").remove()
    svg.append("g")
            .attr("id","scaleBoxes")
            .selectAll("rect")
            .data(curScaleSteps)
            .join("rect")
                .attr("name", (d) => colors(nScaleX(d)))
                .attr("fill", (d) => colors(nScaleX(d)))
                .attr("x", () => {return width -57})
                // .attr("y", () => height - height*(0.04*(curScaleSteps.length+2.2)) - 0)
                .attr("y", (d,i) => {return height-(i*height*.04)-50})
                .attr("height", (d) => {return -0+height*.04})
                .attr("width", (d) => {return -0+height*.04});

    svg.selectAll('#scaleLabels').remove()
    svg.append("g")
            .attr("id","scaleLabels")
            .selectAll("text")
            .data(linSteps)
            .join("text")
                .attr("stroke", (d) => "white")
                .attr("fill", (d) => "white")
                .attr("x", (d,i) => {return width - 24})
                .attr("y", (d,i) => {return height-15-(i*height*.04)})
                .style("font-family", "monospace")
                .attr("font-size", "1em")
                .attr("text-anchor", "left")
                .attr("dominant-baseline","text-top")
                .text((d,i) => Math.round(d).toLocaleString('en-US'))

    svg.select("#riskLabel")
            .attr("y", () => height - height*(0.04*(curScaleSteps.length+1.5))-0)
    
    // svg.selectAll('stop')
    //     .data(linSteps)
    //     .attr('offset', (d) => d/xMax.value)
    //     .attr("stop-color", (d,i) => colors(nScaleX(d)))
}

async function updateDisplay(event) {
    const pageS = document.getElementById("s");
    const pageT = document.getElementById("t");
    const pageU = document.getElementById("u");
    const pageV = document.getElementById("v");
    // const pageW = document.getElementById("w");
    const pageX = document.getElementById("x");
    const pageY = document.getElementById("y");
    const pageZ = document.getElementById("z");
    const evtgt = event.target
    if (evtgt.__data__ !== undefined) {
        const props = event.target.__data__.properties
        if (props.name == '') {
            pageS.innerText = String(props.name)  
            pageT.innerText = String(props.state)
            pageU.innerText = Number(props.prfs)
            pageV.innerText = Number(props.providers)
            // pageW.innerText = Number(props.rprafem).toLocaleString('en-US')
            pageX.innerText = Number(props.births_imp).toLocaleString('en-US')
            pageY.innerText = String(props.ratio)
            pageZ.innerText = Number(props.gap).toLocaleString('en-US')
        } else {
            pageS.innerText = String(props.name)  
            pageT.innerText = String(props.state)
            pageU.innerText = Number(props.prfs)
            pageV.innerText = Number(props.providers)
            // pageW.innerText = Number(props.rprafem).toLocaleString('en-US')
            pageX.innerText = Number(props.births_imp).toLocaleString('en-US')
            pageY.innerText = String(props.ratio)
            pageZ.innerText = Number(props.gap).toLocaleString('en-US')
        }
    } else if (evtgt.__data__ == undefined) {
        pageS.innerText = ''  
        pageT.innerText = ''
        pageU.innerText = ''
        pageV.innerText = ''
        // pageW.innerText = ''
        pageX.innerText = ''
        pageY.innerText = ''
        pageZ.innerText = ''
    } else {}
}

async function clearDisplay(event) {
    const pageS = document.getElementById("s");
    const pageT = document.getElementById("t");
    const pageU = document.getElementById("u");
    const pageV = document.getElementById("v");
    // const pageW = document.getElementById("w");
    const pageX = document.getElementById("x");
    const pageY = document.getElementById("y");
    const pageZ = document.getElementById("z");
    pageS.innerText = ''  
    pageT.innerText = ''
    pageU.innerText = ''
    pageV.innerText = ''
    // pageW.innerText = ''
    pageX.innerText = ''
    pageY.innerText = ''
    pageZ.innerText = ''
}

let props = ref([])
let centroid = ref([])
let sendNo = String('000000')

const message = ref('Randy Jack')

function ChoroMap(data, {
    width,
    height,
    features,
    featuresAlt
    } = {}) {
        let svg = d3.select("#choropleth")
        let g = svg.append("g");

        svg.attr("style", "width: 100%; height: auto; border-radius: 0.5em; outline-style: none;")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            // .on("click",reset)
        
        const zoom = d3.zoom()
            .translateExtent([[(width)*-0.025,height*-0.025],[(width)*1.025,height*1.025]])
            .scaleExtent([.9, 12])
            .on("zoom", zoomed)

        svg.call(zoom)
        svg.call(zoom.transform,
                d3.zoomIdentity
                    .translate(xval.value,yval.value)
                    .scale(kval.value))

        makeMap()

        makeLegend()
        // makePinpad()

        async function fetchData(url) {
            let response = await fetch(url, {
                            method: "GET", // or 'POST'
                            headers: {
                                "Content-Type": "application/json",
                                // "Authorization": 'Api-Key ' + import.meta.env.VITE_DRF_API_KEY,
                                "Cache-Control": 'no-cache, no-store, must-revalidate',
                            },
                            })
            return response.json()
            }

        function tradeValues(signers) {
            svg.selectAll("#markers").remove()
            const markers5 = g.append("g")
                        .attr("class", "markers")
                        .attr("id", "markers")
                        .selectAll("rect")
                        .data(signers)
                        .join("rect")
                            .attr("class", "fadingEffect")
                            .attr("x", (d,i) => d.xPos)
                            .attr("y", (d,i) => d.yPos)
                            .attr("width", 30/kval.value)
                            .attr("height", 30/kval.value)
                            .attr("stroke","black")
                            .attr("fill", markerCol)
                            .attr("transform", returnTranslate())
            const image1 = g.append("g")
                    .attr("class", "markers")
                    .attr("id", "markers")
                    .selectAll('image')
                    .data(signers)
                    .join('image')
                        .attr("class", "fadingEffect")
                        .attr('d',path)
                        .attr('xlink:href',pinSquare)
                        .attr("x", (d,i) => d.xPos)
                        .attr("y", (d,i) => d.yPos)
                        .attr("width", 30/kval.value)
                        .attr("height", 30/kval.value)
                        .attr("transform", returnTranslate())
                        .on("click", clicked1)
                        .append("title")
                            .text((d,i) => "'Pin'-tition!"+'\n'+
                                            d.COUNTY+', '+d.STABRV+'\n'+
                                            d.fname+' '+d.linit
                                            )                                               
            }

        let pinData = []

        // fetchData(url).then(response => {
        //     tradeValues(response)
        // })

        async function postJSON(data, purl) {
                try {
                    let response = await fetch(purl, {
                    method: "PUT", // or 'POST'
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Api-Key ' + import.meta.env.VITE_DRF_API_KEY,
                        "Cache-Control": 'no-cache, no-store, must-revalidate',
                    },
                    body: JSON.stringify(data),
                    });
                    return response
                } catch (error) {
                    console.error("Error:", error);
                }
            }

        function sendEmail(url) {
            let vf = document.getElementById("code").value
            if (vf == sendNo) {
                let fn = document.getElementById("fn").value
                let mn = document.getElementById("mn").value
                let ln = document.getElementById("ln").value
                let em = document.getElementById("em").value
                let emailbody = ["county: "+clist.value[0],
                                    "first name: "+fn,
                                    "middle name: "+mn,
                                    "last name: "+ln,
                                    "email: "+em];
                let data = { 
                    "FIPS": props.value.fips,
                    "xPos": Math.round(centroid.value[0]),
                    "yPos": Math.round(centroid.value[1]),
                    "fname": fn,
                    "mname": mn,
                    "lname": ln,
                    "Email": em,
                    "ID": em+'-'+props.value.fips,
                    "linit": ln.charAt(0)
                };
                Email.send({
                    SecureToken: "57830cd2-43ec-4d5b-bebb-6db16a8b4c2a",
                    To: "data@clueresearch.com",
                    From: "data@clueresearch.com",
                    Subject: "A new Pintition",
                    Body: emailbody,
                }).then(console.log("email record sent"));
                vf = '';
                let testUrl = purl2+data.ID
                postJSON(data,testUrl).then(response => {
                    console.log("data posted")
                    if (response.status == 200) {
                        console.log("post success")
                        fetchData(url).then(response => {
                            tradeValues(response)
                        })
                    } else {
                        console.log("cannot have more than one pin per county")
                        console.log(response)
                    }
                });
            } else {
                console.log("wrong verification code")
                }
            }

        function zoomed(event) {
                    const {transform} = event;
                    g.attr("transform", transform);
                    g.attr("stroke-width", Math.round((1 / transform.k)/0.1)/10);
                    xval.value = transform.x
                    yval.value = transform.y
                    kval.value = transform.k
                    // console.log(kval.value)
                    let scVal = (2-(kval.value-1)*.59/9)/2
                    // console.log(scVal)
                    if (kval.value <= 10) {
                        d3.select("#providers").selectAll("circle")
                            .attr("r",(d) => Math.sqrt(d.properties.providers)*(2-(kval.value-1)*(.59/9)))
                        d3.select("#gap").selectAll("circle")
                            .attr("r",(d) => Math.sqrt(d.properties.gap)*(2-(kval.value-1)*(.59/9)))
                        d3.selectAll("#markers").selectAll('rect')
                            .attr("width", 30/kval.value)
                            .attr("height", 30/kval.value)
                            .attr("transform", returnTranslate())
                        d3.selectAll("#markers").selectAll('image')
                            .attr("width", 30/kval.value)
                            .attr("height", 30/kval.value)
                            .attr("transform", returnTranslate())
                        let plabs = d3.select("#circleScale").selectAll("text")
                            .data([(60/((kval.value*scVal)**2)),(200/((kval.value*scVal)**2))])
                            .join("text")
                                .text((d) => Math.round(d).toLocaleString('en-US'));
                        svg.attr("visibility", "hidden")
                        svg.attr("visibility", "visible")
                        
                    } else if (kval.value > 10) {
                        let clabs = d3.select("#circleScale").selectAll("text")
                        .data([(60/((kval.value*.705)**2)),(200/((kval.value*.705)**2))])
                        .join("text")
                            .text((d) => Math.round(d).toLocaleString('en-US'));
                        d3.selectAll("#markers").selectAll('rect')
                            .attr("width", 30/kval.value)
                            .attr("height", 30/kval.value)
                            .attr("transform", returnTranslate())
                        d3.selectAll("#markers").selectAll('image')
                            .attr("width", 30/kval.value)
                            .attr("height", 30/kval.value)
                            .attr("transform", returnTranslate())

                    } else {}
                    // d3.select("#gap").selectAll("circle")
                    //     .attr("r",(d) => Math.sqrt(d.properties.marg_load)/5)
                    // d3.selectAll("text")
                    //     .attr("font-family", "Montserrat, sans-serif")
            }

        function baseColorSteps(d) {
            // console.log(d)
            if (d == 0) {
                return "#181818"
            } else if (d <= linSteps[0]) {
                return linColorScale[0]
            } else if (d <= linSteps[1]) {
                return linColorScale[1]
            } else if (d <= linSteps[2]) {
                return linColorScale[2]
            } else if (d <= linSteps[3]) {
                return linColorScale[3]
            } else if (d <= linSteps[4]) {
                return linColorScale[4]
            } else if (d <= linSteps[5]) {
                return linColorScale[5]
            } else if (d <= linSteps[6]) {
                return linColorScale[6]
            } else if (d <= linSteps[7]) {
                return linColorScale[7]
            } else {
                return linColorScale[8]}
            }

        function hoverin(event, x) {
            const [[x0, y0], [x1, y1]] = path.bounds(x)
            event.stopPropagation()
            const fips = x.id
            // console.log(fips)
            const test1 = x.properties.fips
            // console.log(test1)
            const test = d3.selectAll('#providers').selectAll('circle')
            // console.log(test)
            const test2 = test.filter((d) => d.id == test1)
            // console.log(test2)
            test2.attr("stroke","white")
            test2.attr("stroke-width", 0.3)
            // test2.attr("stroke-dasharray", "2 1")
            const testCase = d3.selectAll('#gap').selectAll('circle')
            const testCase2 = testCase.filter((d) => d.id == test1)
            testCase2.attr("stroke","white")
            testCase2.attr("stroke-width", 0.3)
            // testCase2.attr("stroke-dasharray", "10 1")
            // const telement = test2._groups[0][0]
            // console.log(telement)
            // const testing = telement.stroke
            // console.log(testing)
            // const test2 = document.querySelec
            // const test2 = document.getElementsByTagName('circle')
            // const test3 = test2.getElementById(test1)
            // const testTwo = test.filter((d) => d.__data__.id == test1)
            function selectCircle(d) {
                if (d.__data__.properties.fips == test1) {
                    console.log("hit")
                } else {
                    console.log("miss")
                }
            }
            // test.forEach((d) => selectCircle(d))
            // console.log(test2)
            }

        function hoverout(event, x) {
            const [[x0, y0], [x1, y1]] = path.bounds(x)
            event.stopPropagation()
            const fips = x.id
            // console.log(fips)
            const test1 = x.properties.fips
            // console.log(test1)
            const test = d3.selectAll('#providers').selectAll('circle')
            // console.log(test)
            const test2 = test.filter((d) => d.id == test1)
            // console.log(test2)
            test2.attr("stroke","black")
            test2.attr("stroke-width", strokeWidth)
            test2.attr("stroke-dasharray", "")
            const testCase = d3.selectAll('#gap').selectAll('circle')
            const testCase2 = testCase.filter((d) => d.id == test1)
            testCase2.attr("stroke","black")
            testCase2.attr("stroke-width", strokeWidth)
            testCase2.attr("stroke-dasharray", "")
            // const telement = test2._groups[0][0]
            // console.log(telement)
            // const testing = telement.stroke
            // console.log(testing)
            // const test2 = document.querySelec
            // const test2 = document.getElementsByTagName('circle')
            // const test3 = test2.getElementById(test1)
            // const testTwo = test.filter((d) => d.__data__.id == test1)
            function selectCircle(d) {
                if (d.__data__.properties.fips == test1) {
                    console.log("hit")
                } else {
                    console.log("miss")
                }
            }
            // test.forEach((d) => selectCircle(d))
            // console.log(test2)
            }

        async function clicked2(event, d) {
            console.log(path.centroid(d))
            const pagess1 = document.getElementById("s1");
            const pagest1 = document.getElementById("t1");
            const pagesu1 = document.getElementById("u1");
            const pagesv1 = document.getElementById("v1");
            // const pagesw1 = document.getElementById("w1");
            const pagesx1 = document.getElementById("x1");
            const pagesy1 = document.getElementById("y1");
            const pagesz1 = document.getElementById("z1");
            const chrp = d3.select("#choropleth");

            const pinBack = d3.select("#pinBack")
            const pinTition = d3.select("#pintition")
            const pi = d3.select("#pintition-form")
                .style("display", "none")
            const cs = d3.select("#county-state").selectAll("li")
                .style("display", "initial")
            let rfacts = Number(d.properties.prfs);
            let provs = Number(d.properties.providers);
            let rfem = Number(d.properties.rprafem);
            let estb = Number(d.properties.births_imp);
            let gapUnit = Number(d.properties.gap);
            // console.log(gapUnit)
            // let rfem = Number(d.properties.rprafem);
            let clname = d.properties.name
            let clprops = d.properties
            // console.log(clprops)
            props.value = d.properties
            // console.log(props.value.fips)
            let clgeo = d.geometry
            let clbounds = path.bounds(d)
            let clcent = path.centroid(d)
            centroid.value = path.centroid(d)
            console.log(clcent[0])
            console.log(centroid.value)
            // console.log(clprops)
            // console.log(clist.value.length)
            // console.log(typeof clist.value)
            // console.log(clist.value)
            const cntyst = clprops.name.concat(", ",clprops.stabrv)
            if (glist.value.includes(clprops.fips)) {
                // d3.select(this).attr("stroke-opacity", "0%")
                d3.select(this).attr("style", "fill-opacity: 100%;stroke:none;");          
                glist.value = glist.value.filter(item => item !== clprops.fips)
                clist.value = clist.value.filter(item => item !== cntyst)
                pinCent.value = pinCent.value.filter(item => item !== clcent)
                rfact.value = rfact.value - rfacts
                cpro.value = cpro.value - provs
                // rafp.value = rafp.value - rfem
                eab.value = eab.value - estb
                shortageGap.value = shortageGap.value - gapUnit
                // console.log(pinCent.value)
                // console.log(glist.value.length)
            } else {
                d3.select("#startPintition").style("visibility", "visible")
                // d3.select(this).style("opacity","50%")
                d3.select(this).attr("style", "fill-opacity: 100%;")
                d3.select(this).attr("stroke", "black")
                d3.select(this).attr("stroke-width", 0.5)

                glist.value.push(clprops.fips)
                clist.value.push(cntyst)
                pinCoords.value.push(clbounds)
                pinCent.value.push(clcent)
                rfact.value = rfact.value + rfacts
                cpro.value = cpro.value + provs
                // console.log(pinCent.value)
                // rafp.value = rafp.value + rfem
                // console.log(eab.value)
                eab.value = eab.value + estb
                // console.log(eab.value)
                // console.log(shortageGap.value)
                shortageGap.value = shortageGap.value + gapUnit
                // console.log(shortageGap.value)
            };
            if (glist.value.length == 0 | glist.value.length >= 2) {
                    // console.log(glist.value.length)
                    d3.select("#startPintition").style("visibility", "hidden")
                } else if (glist.value.length == 1) {
                    d3.select("#startPintition").style("visibility", "visible")
                }

            // console.log(rfact.value)
            pagesu1.innerText = Math.round(Number(rfact.value/clist.value.length))
            pagesv1.innerText = cpro.value
            // pagesw1.innerText = rafp.value
            pagesx1.innerText = Number(eab.value).toLocaleString('en-US')
            pagesy1.innerText = Number(cpro.value*1000/eab.value).toFixed(2)
            pagesz1.innerText = Number(shortageGap.value).toLocaleString('en-US')
            
            chrp.attr("opacity", "100%")
            chrp.attr("visibility", "visible")
            }

        async function clicked3(event, d) {
            const [[x0, y0], [x1, y1]] = path.bounds(d);
            event.stopPropagation();
            svg.transition().duration(750).call(
            zoom.transform,
            d3.zoomIdentity
            .translate(width / 2, height / 2)
            .scale(Math.min(10, 0.9 / Math.max((x1 - x0) / width, (y1 - y0) / height)))
            .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
            d3.pointer(event, svg.node()),
            );

            }

        async function pinDrop(event) {
            let cx = event.clientX
            let cy = event.clientY

            // const [[x0, y0], [x1, y1]] = path.bounds(d);
            event.stopPropagation();
            svg.transition().duration(750).call(
            zoom.transform,
            d3.zoomIdentity
            .translate(width / 2, height / 2)
            .scale(10)
            .translate(-(cx)/2,-(cy)/2)
            // d3.pointer(event, svg.node()),
            );
            console.log(kval.value)
            
        }

        async function reset() {
            d3.select("#startPintition").attr("visibility", "hidden")
            d3.select("#pintition").attr("visibility", "hidden")
            d3.select("#pintition-form").style("display","none")
            g.transition().style("fill", null);
            svg.transition().duration(750).call(
                zoom.transform,
                d3.zoomIdentity,
                d3.zoomTransform(svg.node()).invert([width / 2, height / 2])
            );
            clearList()
        }

        async function clearList() {
            const cntys = d3.selectAll('#counties')
                .selectAll('path')
                .attr("style", "fill-opacity: 100%;stroke:none;stroke-width: 0.2")
            // reset();
            document.querySelector("#startPintition").style.visibility = "hidden"
            document.querySelector("#pintition-info").style.display = "none"
            document.querySelector("#pintition-form").style.display = "none"
            document.querySelector("#verifyInstructions").style.display = "none"

            const vf = document.getElementById("code").value
            const fn = document.getElementById("fn").value
            const mn = document.getElementById("mn").value
            const ln = document.getElementById("ln").value
            const em = document.getElementById("em").value
        
            clist.value = [];
            glist.value = [];
            pinCoords.value = [];
            pinCent.value = [];
            rfact.value = 0;
            cpro.value = Number(0);
            // rafp.value = Number(0);
            eab.value = Number(0);
            const pagess1 = document.getElementById("s1");
            const pagest1 = document.getElementById("t1");
            const pagesu1 = document.getElementById("u1");
            const pagesv1 = document.getElementById("v1");
            // const pagesw1 = document.getElementById("w1");
            const pagesx1 = document.getElementById("x1");
            const pagesy1 = document.getElementById("y1");
            const pagesz1 = document.getElementById("z1");
            pagesu1.innerText = '';
            pagesv1.innerText = '';
            // pagesw1.innerText = '';
            pagesx1.innerText = '';
            pagesy1.innerText = '';
            pagesz1.innerText = '';
        }
        
        // Make the DIV element draggable:
        // dragElement(document.getElementById("mapPin"));

        function dragElement(elmnt) {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(elmnt.id + "header")) {
            // if present, the header is where you move the DIV from:
            document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        } else {
            // otherwise, move the DIV from anywhere inside the DIV:
            elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            // console.log(e)
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
            // console.log(e.clientX)
            // console.log(e.clientY)
        }

        function closeDragElement(e) {
            let cx = e.pageX
            let cy = e.pageY

            let wsw = window.screen.width
            let wsh = window.screen.height

            let hmarg = (wsw - 1080)/2
            console.log(cx)
            console.log(cy)

            console.log(e)
            console.log(elmnt)

            const ch = d3.select('#choropleth')
            console.log(ch._groups[0][0].attributes)
            console.log(ch._groups[0][0].childNodes[0].attributes)

            pinDrop(e)

            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
        }

        async function cancelPintition() {
            document.querySelector("#pintition-form").style.display = "none"
            document.querySelector("#verifyInstructions").style.display = "none"
            document.querySelector("#pintSubmit").style.display = "none"
            document.querySelector("#code").style.visibility = "hidden"
            document.querySelector("#verify").style.display = "block"
            document.getElementById("fn").value = ''
            document.getElementById("mn").value = ''
            document.getElementById("ln").value = ''
            document.getElementById("em").value = ''
            document.getElementById("code").value = ''
            if (glist.value.length == 0 | glist.value.length >= 2) {
                    d3.select("#startPintition").style("visibility", "hidden")
                } else if (glist.value.length == 1) {
                    d3.select("#startPintition").style("visibility", "visible")
                }
        }

        function submitPintition() {           
            sendEmail(url)
            cancelPintition()
            // document.querySelector("#pintition-form").style.display = "none"
            // alert("Your Pintition was Successfully Submitted!\nCheck back soon to see the Pintition added to the map.\nPlease note that it may take up to 24 hours before your pin appears on the map.\nThank you for supporting Maternal Mental Health.");
            // if (glist.value.length == 0 | glist.value.length >= 2) {
            //         d3.select("#startPintition").style("visibility", "hidden")
            //     } else if (glist.value.length == 1) {
            //         d3.select("#startPintition").style("visibility", "visible")
            //     }
        }

        async function signPintition() {
            document.querySelector("#pintition-info").style.display = "none"
            alert("You signed the Pintition!\nThank you for supporting Maternal Mental Health.");
            if (glist.value.length == 0 | glist.value.length >= 2) {
                    d3.select("#startPintition").style("visibility", "hidden")
                } else if (glist.value.length == 1) {
                    d3.select("#startPintition").style("visibility", "visible")
                }
        }

        function verifyEmail() {
            let code = document.getElementById("code")
            code.style.visibility = "visible";
            let verify = document.getElementById("verify")
            verify.style.display = 'none';
            let submit = document.getElementById("pintSubmit")
            submit.style.display = 'block';
            // let pintInfo = document.getElementById("pintition-info")
            // pintInfo.style.height = '40em';
            let verifyInstructions = document.getElementById("verifyInstructions")
            verifyInstructions.style.display = 'block';
            let rndno = Math.round(Math.random()*999999)
            // console.log(String(rndno))
            let nozeroes = 6-rndno.toString().length
            // let testhash = createHash('sha256').update(rndno).digest('hex')
            // let sendNo = String('000000')
            if (nozeroes == 0) {
                sendNo = String(rndno)
            } else if (nozeroes == 1) {
                sendNo = String(0)+rndno.toString()
            } else if (nozeroes == 2) {
                sendNo = String(0)+String(0)+rndno.toString()
            } else if (nozeroes == 3) {
                sendNo = String(0)+String(0)+String(0)+rndno.toString()
            } else if (nozeroes == 4) {
                sendNo = String(0)+String(0)+String(0)+String(0)+rndno.toString()
            } else if (nozeroes == 5) {
                sendNo = String(0)+String(0)+String(0)+String(0)+String(0)+rndno.toString()
            } else {
                console.log("random number failed")
            }
            console.log(sendNo)
            let em = document.getElementById("em").value
            console.log(em)
            const e2 = ("02354")
            Email.send({
                    SecureToken: "57830cd2-43ec-4d5b-bebb-6db16a8b4c2a",
                    To: em,
                    From: "data@clueresearch.com",
                    Subject: "Here is your 6-digit Verification Code",
                    Body: sendNo,
                })
                    .then(function (message) {
                    alert("A 6-digit verification code was sent to\n"+em+"\nPlease check your email for the 6-digit code and enter it into the box labeled '6-digit Verification Code'.\nThen press 'Submit' to join the 'Pin'-tition!.")
                    });
        }

        function startPintition(event) {
            // const cs = d3.select("#county-state").selectAll("li")
            //     .style("display", "none")
            // console.log(event)
            // console.log(pinCent.value[0][0])
            // console.log(pinCent.value[0][1])
            
            svg.call(zoom.transform,
                     d3.zoomIdentity
                        .translate(width / 1.5, height / 2)
                        .scale(10)
                        .translate(-(pinCent.value[0][0]), -(pinCent.value[0][1])),
                        )
            const pf = d3.select("#pintition-form")
                .style("display", "block");
            const pi = d3.select("#pintition-info")
                .style("display", "none");
            const sp = d3.select("#startPintition")
                .style("visibility", "hidden")
        }

        function readPintition() {
            // const cs = d3.select("#county-state").selectAll("li")
            //     .style("display", "none")
            const pi = d3.select("#pintition-info")
                .style("display", "block");
            const pf = d3.select("#pintition-form")
                .style("display", "none");
            // const sp = d3.select("#startPintition")
            //     .style("visibility", "hidden")
        }

        function pintInfo() {
            const pi = d3.select("#pintition-info").style("display", "none");
            if (glist.value.length == 0 | glist.value.length >= 2) {
                    d3.select("#startPintition").style("visibility", "hidden")
                } else if (glist.value.length == 1) {
                    d3.select("#startPintition").style("visibility", "visible")
                }
        }

        // const testFill = d3.select('#pcLogoText').append("g")
        // testFill.append('rect')
        //     .attr("height", height/20)
        //     .attr("width", width/2)
        //     .attr("x", 50)
        //     .attr("y", 50)
        //     .attr("stroke", "black")
        //     .attr("fill", "url(#mainGradient)")

        function makeLegend() {
            const histo = d3.select('#choropleth');
            const g = histo.append("g").attr("id", "scales")
            g.append("rect")
                .attr("id", "riskbg")
                .attr("x", () => width-70)
                .attr("y", () => height - height*(0.04*(levels.value+2.2)) - 0)
                .attr("width", 70)
                .attr("height", height*(0.04*(levels.value+2)) + 0)
                .attr("fill", "#242424")
                .attr("opacity", "90%")
                .attr("rx", "8")
            
            g.append("rect")
                .attr("id", "circlebg")
                .attr("x", () => width-145)
                .attr("y", () => height - height*(0.037*4) - 0)
                .attr("width", 75)
                .attr("height", 85)
                .attr("fill", "#242424")
                .attr("opacity", "90%")
                .attr("rx", "8")
    
            // g.append("rect")
            //     .attr("id", "pinbg")
            //     .attr("x", () => width-145)
            //     .attr("y", () => height - height*(0.0376*7) - 5)
            //     .attr("width", 75)
            //     .attr("height", 75)
            //     .attr("fill", "#242424")
            //     .attr("opacity", "90%")
            //     .attr("rx", "8")

            g.append("circle")
                .attr("id", "largeCircle")
                .attr("cx", () => width-107.5)
                .attr("cy", () => height - height*(0.0365*1) - 17.5) 
                .attr("r",(d) => 28.28)
                .attr("stroke","black")
                .attr("fill", "white")
                .attr("fill-opacity", "50%")
                .attr("visibility", "visible")
                .style("pointer-events", "none")

            g.append("circle")
                .attr("id", "smallCircle")
                .attr("cx", () => width-107.5)
                .attr("cy", () => height - height*(0.0365*1) - 7.5) 
                .attr("r",(d) => 15.49)
                .attr("stroke","black")
                .attr("fill", "white")
                .attr("fill-opacity", "0%")
                .attr("visibility", "visible")
                .style("pointer-events", "none")
            
            g.append("text")
                .attr("id","providerLabel")
                .attr("x", () => width-107.5)
                .attr("y", () => height*0.875)
                // .attr("stroke", (d) => "white")
                .attr("fill", (d) => "white")
                .attr("text-anchor", "middle")
                .attr("dominant-baseline","middle")
                .attr("visibility", "visible")
                .attr("font-family", "Montserrat, sans-serif")
                .attr("font-size", '0.8em')
                .text('Providers');                

            g.append("text")
                .attr("id","gapLabel")
                .attr("x", () => width-107.5)
                .attr("y", () => height*0.875)
                // .attr("stroke", (d) => "white")
                .attr("fill", (d) => "white")
                .attr("visibility", "hidden")
                .attr("text-anchor", "middle")
                .attr("dominant-baseline","middle")
                .attr("visibility", "hidden")
                .attr("font-family", "Montserrat, sans-serif")
                .text('Gap'); 

            g.append("text")
                .attr("id","riskLabel")
                .attr("x", () => width-35)
                .attr("y", () => height - height*(0.04*(curScaleSteps.length+1.5))-0)
                // .attr("stroke", (d) => "white")
                .attr("fill", (d) => "white")
                .attr("text-anchor", "middle")
                .attr("dominant-baseline","middle")
                .attr("visibility", "visible")
                .attr("font-family", "Montserrat, sans-serif")
                .text('Risk'); 
                
            g.append("g")
                .attr("id","circleScale")
                .selectAll("text")
                .data([60,200])
                .join("text")
                    // .attr("stroke", (d) => "white")
                    .attr("fill", (d) => "white")
                    .attr("x", () => {return width - 107.5})
                    .attr("y", (d,i) => {return height - (i+1)*0.04*height - 5})
                    .attr("font-size", "0.8em")
                    .attr("visibility", "visible")
                    .attr("text-anchor", "middle")
                    .attr("dominant-baseline","text-top")
                    .attr("font-family", "Montserrat, sans-serif")
                    .text((d) => Math.round(d).toLocaleString('en-US'));            
            
            g.append("g")
                .attr("id","scaleBoxes")
                .selectAll("rect")
                .data(curScaleSteps)
                .join("rect")
                    .attr("name", (d) => colorScale(nScaleX(d)))
                    // .attr("stroke", (d) => colorScale(nScaleX(d)))
                    .attr("fill", (d) => colorScale(nScaleX(d)))
                    .attr("x", () => {return width -57})
                    // .attr("y", (d,i) => {return -0.15*height-0.03*height+height-(i*height*nScaleX(linScaleStep*0.4)) - height*nScaleX(linScaleStep*0.4)})
                    // .attr("height", (d) => {return -0+height*nScaleX(linScaleStep*0.4)})
                    // .attr("width", (d) => {return -0+height*nScaleX(linScaleStep*0.4)})
                    .attr("y", (d,i) => {return height-(i*height*.04)-50})
                    .attr("height", (d) => {return -0+height*.04})
                    .attr("width", (d) => {return -0+height*.04});
            // histo.append("g")
            //     .selectAll("line")
            //     .data(linSteps)
            //     .join("line")
            //         .attr("name", (d) => colorScale(nScaleX(d)))
            //         .attr("stroke", (d) => colorScale(nScaleX(d)))
            //         .attr("fill", (d) => colorScale(nScaleX(d)))
            //         .attr("x1", () => {return width+8})
            //         .attr("x2", () => {return width-20})
            //         .attr("y1", (d,i) => {return -0.04*height+height-(i*height*nScaleX(linScaleStep*0.5)) - height*nScaleX(linScaleStep*0.5)})
            //         .attr("y2", (d,i) => {return -0.04*height+height-(i*height*nScaleX(linScaleStep*0.5)) - height*nScaleX(linScaleStep*0.5)})
            // histo.append("g")
            //     .selectAll("line")
            //     .data(linSteps)
            //     .join("line")
            //         .attr("name", (d) => colorScale(nScaleX(d)))
            //         .attr("stroke", (d) => colorScale(nScaleX(d)))
            //         .attr("fill", (d) => colorScale(nScaleX(d)))
            //         .attr("x1", () => {return width - 54})
            //         .attr("x2", () => {return width - 48})
            //         .attr("y1", (d,i) => {return -0.04*height+height-(i*height*nScaleX(linScaleStep*0.5)) - height*nScaleX(linScaleStep*0.5)})
            //         .attr("y2", (d,i) => {return -0.04*height+height-(i*height*nScaleX(linScaleStep*0.5)) - height*nScaleX(linScaleStep*0.5)})
            g.append("g")
                .attr("id","scaleLabels")
                .selectAll("text")
                .data(linSteps)
                .join("text")
                    // .attr("stroke", (d) => "white")
                    .attr("fill", (d) => "white")
                    .attr("x", (d,i) => {return width - 24})
                    .attr("y", (d,i) => {return height-15-(i*height*.04)})
                    .style("font-family", "monospace")
                    .attr("font-size", "1.2em")
                    .text((d) => Math.round(d).toLocaleString('en-US'));

            g.append("text")
                .attr("id", "attribution")
                .attr("x", width*0.55)
                .attr("y", height*.995)
                .attr("text-anchor", "left")
                .attr("stroke", (d) => "white")
                .attr("fill", (d) => "white")
                .style("font-family", "monospace")
                .attr("font-size", "0.8em")
                .text('© 2023 Policy Center for Maternal Mental Health')
            
            // const markers7 = g.append("g")
            //         // .attr("class", "markers")
            //         .attr("id", "markerKey")
            //         .selectAll("rect")
            //         .data([[width,height]])
            //         .join("rect")
            //             .attr("x", (d) => { return d[0] - 122.5 })
            //             .attr("y", (d) => { return d[1] - 132.5 })
            //             .attr("width", 30)
            //             .attr("height", 30)
            //             .attr("stroke","black")
            //             .attr("fill", "#2f7f34")
            //             // .attr("transform", "rotate(-45 580 585)")

            // const image2 = g.append("g")
            //         // .attr("class", "markers")
            //         .attr("id", "markerKey")
            //         .selectAll('image')
            //         .data([[width,height]])
            //         .join('image')
            //             .attr('d',path)
            //             .attr('xlink:href',pinSquare)
            //             .attr("x", (d) => { return d[0] - 122.5 })
            //             .attr("y", (d) => { return d[1] - 132.5 })
            //             .attr("width", 30/kval.value)
            //             .attr("height", 30/kval.value)
                        

            // g.append("g")
            //     .attr("id","markerKey")
            //     .selectAll("text")
            //     .data([[width,height]])
            //     .join("text")
            //         .attr("x", (d) => { return d[0] - 107.5 })
            //         .attr("y", (d) => { return d[1] - 145 })
            //         // .attr("stroke", (d) => "white")
            //         .attr("fill", (d) => "white")
            //         .attr("text-anchor", "middle")
            //         .attr("dominant-baseline","middle")
            //         .attr("visibility", "visible")
            //         .attr("font-family", "Montserrat, sans-serif")
            //         .attr("font-size", '0.8em')
            //         .text("'Pin'-titions");   

            var padding = 0

            // g.append('rect')
            //     .attr("id",'contScale')
            //     // .classed('filled', true)
            //     .attr('style', 'fill:url(#mainGradient)')
            //     .attr('x', width - 35)
            //     .attr('y', height / 2 - 0.03*height )
            //     .attr('width', 100)
            //     .attr('height', 100);

            // Use the gradient to set the shape stroke, via CSS.
            svg.append('rect')
                // .classed('outlined', true)
                .attr('style','fill:none;stroke:url(#mainGradient);stroke-width:4;')
                .attr('x', width / 2)
                .attr('y', height / 2)
                .attr('width', (width / 2))
                .attr('height', height );
        }

        function makeMap() {
            const map = g.append("g")
                .attr("id","counties")
                .selectAll("path")
                .data(features.features)
                .join("path")
                    .attr("id", (d) => d.id)
                    .attr("fill", (d,i) => baseColorSteps(V[Im.get(If[i])]))
                    // .attr("stroke", "white")
                    // .attr("stroke-width", (d) => 0.2/kval.value)
                    .attr("d", path)
                    // .attr("id", (d, i) => d.properties.abbr)
                    .attr("class", "county")
                    // .on("click.one", clicked1)
                    .on("click.two", clicked2)
                    .on("click.three", clicked3)
                    .on("mouseenter", hoverin)
                    .on("mouseleave", hoverout)

            map.append("title")
                .text((d, i) => d.properties.name+" "+d.properties.stabrv);
                // .text((d, i) => d.properties.name+" "+V[Im.get(If[i])].toFixed(0));

            const statemap = g.append("g")
                .attr("id","states")
                .selectAll("path")
                .data(featuresAlt.features)
                .join("path")
                    .attr("fill", "none")
                    // .attr("fill", (d,i) =>  linColorStep(V[Im.get(If[i])]))
                    .attr("stroke", stroke)
                    .attr("d", path)
                    .attr("class", "state")

            statemap.append("title")
                .text((d, i) => d.properties.state+" "+V[Im.get(If[i])].toFixed(0));
            
            svg.select("#outline").remove()
                if (statemesh != null) {
                    g.append("path")
                        .attr("id","outline")
                        .attr("pointer-events", "none")
                        .attr("fill", "none")
                        .attr("stroke", stroke)
                        .attr("stroke-linecap", strokeLinecap)
                        .attr("stroke-linejoin", strokeLinejoin)
                        .attr("stroke-width", strokeWidth)
                        .attr("stroke-opacity", strokeOpacity)
                        .attr("d", path(statemesh))
                    }

            const providers = g.append("g")
                    .attr("class", "circles")
                    .attr("id", "providers")
                    .selectAll("circle")
                    .data(features.features.filter(d => d.properties.providers >= 1))
                    .join("circle")
                    .attr("id", (d) => d.id)
                    .attr("cx", function(d) { return path.centroid(d)[0]; })
                    .attr("cy", function(d) { return path.centroid(d)[1]; })      
                    .attr("r",(d) => Math.sqrt(d.properties.providers)*2)
                    .attr("stroke","black")
                    .attr("fill", "white")
                    .attr("fill-opacity", "50%")
                    .attr("visibility", "visible")
                    .style("pointer-events", "none")

            const gap = g.append("g")
                    .attr("class", "circles")
                    .attr("id", "gap")
                    .selectAll("circle")
                    .data(features.features.filter(d => d.properties.gap >= 1))
                    .join("circle")
                    .attr("id", (d) => d.id)
                    .attr("cx", function(d) { return path.centroid(d)[0]; })
                    .attr("cy", function(d) { return path.centroid(d)[1]; })      
                    .attr("r",(d) => Math.sqrt(d.properties.gap)*2)
                    .attr("stroke","black")
                    .attr("fill", "white")
                    .attr("fill-opacity", "50%")
                    .attr("visibility", "hidden")
                    .style("pointer-events", "none")

            // const births = g.append("g")
            //         .attr("class", "circles")
            //         .attr("id", "births")
            //         .selectAll("circle")
            //         .data(features.features.filter(d => d.properties.births_imp >= 1))
            //         .join("circle")
            //         .attr("id", (d) => d.id)
            //         .attr("cx", function(d) { return path.centroid(d)[0]; })
            //         .attr("cy", function(d) { return path.centroid(d)[1]; })      
            //         .attr("r",(d) => Math.sqrt(d.properties.births_imp/100))
            //         .attr("stroke","black")
            //         .attr("fill", "white")
            //         .attr("fill-opacity", "50%")
            //         .attr("visibility", "hidden")
            //         .style("pointer-events", "none")

            // const markers4 = g.append("g")
            //         .attr("class", "markers")
            //         .attr("id", "markers")
            //         .selectAll("rect")
            //         .data([[680,380]])
            //         .join("rect")
            //             .attr("x", (d) => d[0] - 15)
            //             .attr("y", (d) => d[1] - 15)
            //             .attr("width", 30)
            //             .attr("height", 30)
            //             .attr("stroke","black")
            //             .attr("fill", "#3f7cbf")
            //             .attr("transform", "rotate(-45 680 380)")

            // const markers5 = g.append("g")
            //         .attr("class", "markers")
            //         .attr("id", "markers")
            //         .selectAll("rect")
            //         .data(mLocs)
            //         .join("rect")
            //             .attr("x", (d,i) => d.xPos)
            //             .attr("y", (d,i) => d.yPos)
            //             .attr("width", 30/kval.value)
            //             .attr("height", 30/kval.value)
            //             .attr("stroke","black")
            //             .attr("fill", "#2f7f34")
            //             .attr("transform", returnTranslate())
            
            // const image1 = g.append("g")
            //         .attr("class", "markers")
            //         .attr("id", "markers")
            //         .selectAll('image')
            //         .data(mLocs)
            //         .join('image')
            //             .attr('d',path)
            //             .attr('xlink:href',pinSquare)
            //             .attr("x", (d,i) => d.xPos)
            //             .attr("y", (d,i) => d.yPos)
            //             .attr("width", 30/kval.value)
            //             .attr("height", 30/kval.value)
            //             .attr("transform", returnTranslate())
            //             .on("click", clicked1)
            //             .append("title")
            //                 .text((d,i) => "'Pin'-tition!"+'\n'+
            //                                 d.County+', '+d.Stabrv+'\n'+
            //                                 d.First_Name+' '+d.LN_Initial
            //                                 )

            // const [[x0, y0], [x1, y1]] = path.bounds(d);
            const labels2 = g.append("g")
                .attr("class", "labels2")
                .selectAll("text")
                .data(featuresAlt.features)
                .join("text")
                    .text((d) => d.properties.state)
                    .attr("x", function(d) { return path.centroid(d)[0]; })
                    .attr("y", function(d) { return path.centroid(d)[1]; })      
                    .attr("font-size", ".5em")
                    .attr("text-anchor", "middle")
                    .attr("dominant-baseline","middle")
                    // .attr("stroke", stroke)
                    .style("pointer-events", "none")

            const labels = g.append("g")
                .attr("class", "labels")
                .selectAll("text")
                .data(features.features)
                .join("text")
                    .text((d) => d.properties.label)
                    .attr("x", function(d) { return path.centroid(d)[0]; })
                    .attr("y", function(d) { return path.centroid(d)[1]; })      
                    .attr("font-size", "min(0.8em,1.2pt)")
                    .attr("text-anchor", "middle")
                    .attr("dominant-baseline","middle")
                    // .attr("text-align", "center")
                    .style("pointer-events", "none")
            
            const box = document.querySelector("#counties");
            box.addEventListener("mousemove", updateDisplay, false);
            box.addEventListener("mouseenter", updateDisplay, false);
            box.addEventListener("mouseleave", clearDisplay, false);
            
            labelShow()

            // const mapPin = g.append("g")
            //     .attr("id", "parachute")
            //     .append("rect")
            //         .attr("x", (d) => width - 50)
            //         .attr("y", (d) => height - 200)
            //         .attr("width", 50/kval.value)
            //         .attr("height", 50/kval.value)
            //         .attr("stroke","black")
            //         .attr("fill", "#484848")

            // mapPin.append('g')
            //         .attr("class", "markers")
            //         .attr("id", "parachute")
            //         .select('image')
            //             .attr('d',path)
            //             .attr('xlink:href',pinSquare)
            //             .attr("x", (d,i) => width - 220)
            //             .attr("y", (d,i) => height - 220)
            //             .attr("width", 50/kval.value)
            //             .attr("height", 50/kval.value)
            //             // .attr("transform", returnTranslate())
            //             .on("click", clicked1)
            //             .append("title")
            //                 .text((d,i) => 'Pintition!\n'+d.Name+'\n'+d.Signers+' signers')

            // const markers7b = g.append("g")
            //         // .attr("class", "markers")
            //         .attr("id", "markerKey")
            //         .selectAll("rect")
            //         .data([[width,height]])
            //         .join("rect")
            //             .attr("x", (d) => { return d[0] - 222.5 })
            //             .attr("y", (d) => { return d[1] - 132.5 })
            //             .attr("width", 30)
            //             .attr("height", 30)
            //             .attr("stroke","black")
            //             .attr("fill", "#2f7f34")
            //             // .attr("transform", "rotate(-45 580 585)")

            // const image2b = g.append("g")
            //         // .attr("class", "markers")
            //         .attr("id", "markerKey")
            //         .selectAll('image')
            //         .data([[width,height]])
            //         .join('image')
            //             .attr('d',path)
            //             .attr('xlink:href',pinSquare)
            //             .attr("x", (d) => { return d[0] - 122.5 })
            //             .attr("y", (d) => { return d[1] - 132.5 })
            //             .attr("width", 30/kval.value)
            //             .attr("height", 30/kval.value)


            // const markers5b = g.append("g")
            //         .attr("class", "markers")
            //         .attr("id", "markers")
            //         .selectAll("rect")
            //         .data(mLocs)
            //         .join("rect")
            //             .attr("x", (d,i) => d.xPos+100)
            //             .attr("y", (d,i) => d.yPos)
            //             .attr("width", 30/kval.value)
            //             .attr("height", 30/kval.value)
            //             .attr("stroke","black")
            //             .attr("fill", "#2f7f34")
            //             .attr("transform", returnTranslate())
            
            // const image1b = g.append("g")
            //         .attr("class", "markers")
            //         .attr("id", "markers")
            //         .selectAll('image')
            //         .data(mLocs)
            //         .join('image')
            //             .attr('d',path)
            //             .attr('xlink:href',pinSquare)
            //             .attr("x", (d,i) => d.xPos)
            //             .attr("y", (d,i) => d.yPos)
            //             .attr("width", 30/kval.value)
            //             .attr("height", 30/kval.value)
            //             .attr("transform", returnTranslate())
            //             .on("click", clicked1)
            //             .append("title")
            //                 .text((d,i) => 'Pintition!\n'+d.Name+'\n'+d.Signers+' signers')


            function oview_act() {
                if (oviewOn.value == true) {
                    oviewOn.value = false
                } else if (oviewOn.value == false) {
                    oviewOn.value = true
                } else {
                    console.log("error activate()")
                }
                // console.log(oviewOn.value)
            }

            function rfscores_act() {
                if (rfscoresOn.value == true) {
                    rfscoresOn.value = false
                } else if (rfscoresOn.value == false) {
                    rfscoresOn.value = true
                } else {
                    console.log("error activate()")
                }
                // console.log(rfscoresOn.value)
            }

            function cprovs_act() {
                if (cprovsOn.value == true) {
                    cprovsOn.value = false
                } else if (cprovsOn.value == false) {
                    cprovsOn.value = true
                } else {
                    console.log("error activate()")
                }
                // console.log(cprovsOn.value)
            }

            function pratio_act() {
                if (pratioOn.value == true) {
                    pratioOn.value = false
                } else if (pratioOn.value == false) {
                    pratioOn.value = true
                } else {
                    console.log("error activate()")
                }
                // console.log(pratioOn.value)
            }

            function psgap_act() {
                if (psgapOn.value == true) {
                    psgapOn.value = false
                } else if (psgapOn.value == false) {
                    psgapOn.value = true
                } else {
                    console.log("error activate()")
                }
                // console.log(psgapOn.value)
            }

            const clrButton = document.querySelector("#clear")
            clrButton.addEventListener("click",() => {
                clearList();
            } , false)
            ;

            const resetButton = document.querySelector("#reset")
            resetButton.addEventListener("click",() => {
                reset();
            } , false)
            ;

            // const pintButton = document.querySelector("#startPintition")
            // pintButton.addEventListener("click",(event) => {
            //     startPintition(event);
            // } , false)
            // ;
            
            const sp = d3.select('grabPin')
            const markers7 = sp.append("g")
                    // .attr("class", "markers")
                    .attr("id", "markerKey")
                    .selectAll("rect")
                    .data([[30,30]])
                    .join("rect")
                        .attr("x", (d) => { return width })
                        .attr("y", (d) => { return height })
                        .attr("width", 30)
                        .attr("height", 30)
                        .attr("stroke","black")
                        .attr("fill", "#2f7f34")
                        // .attr("transform", "rotate(-45 580 585)")

            // const image2 = sp.append("g")
            //         // .attr("class", "markers")
            //         .attr("id", "markerKey")
            //         .selectAll('image')
            //         .data([[width,height]])
            //         .join('image')
            //             .attr('d',path)
            //             .attr('xlink:href',pinSquare)
            //             .attr("x", 0)
            //             .attr("y", 0)
            //             .attr("width", 30/kval.value)
            //             .attr("height", 30/kval.value)
            // console.log(sp)
            // const sps = sp.append('svg')
            sp.attr("style", "width: 100%, height: auto; border-radius: 0.5em; outline-style: none;")
                .attr("width", 30)
                .attr("height", 30)
                .attr("viewBox", [0, 0, 30, 30])
                .append("rect")
                    .attr("width", 30)
                    .attr("height", 30)
                    .attr("stroke","black")
                    .attr("fill", "#2f7f34")

            const spa = sp.append("g")
                    // .attr("class", "markers")
                    .attr("id", "markerKey")
                    .selectAll('image')
                    .data([[width,height]])
                    .join('image')
                        .attr('d',path)
                        .attr('xlink:href',pinSquare)
                        .attr("x", (d) => { return 30/kval.value/2 })
                        .attr("y", (d) => { return 30/kval.value/2 })
                        .attr("width", 30/kval.value)
                        .attr("height", 30/kval.value)

            const verifySubmit = document.querySelector("#verify")
            verifySubmit.addEventListener("click",() => {
                verifyEmail();
            } , false)
            ;

            const pintSubmit = document.querySelector("#pintSubmit")
            pintSubmit.addEventListener("click",() => {
                submitPintition();
            } , false)
            ;
            
            const pintClose = document.querySelector("#pintClose")
            pintClose.addEventListener("click",() => {
                cancelPintition();
            } , false)
            ;

            const pntSign = document.querySelector("#pintSign")
            pntSign.addEventListener("click",() => {
                signPintition();
            } , false)
            ;

            const pntInfo = document.querySelector("#pintInfo")
            pntInfo.addEventListener("click",() => {
                pintInfo();
            } , false)
            ;


            const oview = document.querySelector("#ov")
            oview.addEventListener("click",() => {
                oview_act();
            } , false)
            ;

            const rfscores = document.querySelector("#rs")
            rfscores.addEventListener("click",() => {
                rfscores_act();
            } , false)
            ;

            const cprovs = document.querySelector("#cp")
            cprovs.addEventListener("click",() => {
                cprovs_act();
            } , false)
            ;

            const pratio = document.querySelector("#pr")
            pratio.addEventListener("click",() => {
                pratio_act();
            } , false)
            ;

            const psgap = document.querySelector("#sg")
            psgap.addEventListener("click",() => {
                psgap_act();
            } , false)
            ;
        }

        return svg.node();
}

// DEFINE LIFECYCLE HOOKS
onMounted(() => {
    ChoroMap(dataset, {
        width: width,
        height: height,
        features: counties,
        featuresAlt: states
    })
})

onUpdated(() => {

})
</script>

<style>
/* @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css"); */
* {
    /* box-sizing: border-box; */
    max-width: 975px;
    margin:auto;
    /* font-family: monospace; */
}
#app {
    /* position: absolute; */
    top: 0;
    /* max-width: 1260px; */
    /* margin: 0; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    /* left:1vw; */
    /* margin: auto;
    align-content: center;
    justify-content: center; */
    /* overflow-y: scroll; */
}

.risk {
    position: sticky;
    top:0;
    /* min-height: 100vh; */
    /* max-width: 960; */
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 2fr; */
    /* align-items: center; */
}



.header {
    position: sticky;
    top:0vw;
    display: grid;
    width: 100%;
    background-color: white;
    grid-template-columns: 2fr 1fr 1.5fr;
    list-style-type: none;
    /* border-bottom: .1rem solid; */
    padding-left: 4vw;
    /* height: min(15vw,100px); */
    /* height: max(7vw,7em); */
    height: min(9em,12vw);
    /* max-height: max(10em); */
    /* max-height: max(4em,5vw); */
    padding-left: 3em;
    align-content: center;
    z-index: 10;
    color: #631a6c;
    /* overflow: visible; */
    /* width: 980; */
    /* height: 70px; */
    /* position: fixed; */
    /* height: 100px;
    font-size: 16px; */
    /* right:0; */
    /* margin:0; */
    /* padding-top:0.5em; */
    /* background-image: linear-gradient(to left, rgba(255,0,0,0), rgba(255,0,0,1)); */
}

#title-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.title-box {
    /* font-size: min(3.6vw,36px); */
    /* text-align: center; */
    /* align-content: center; */
    /* font-family: 'Montserrat'; */
    /* font-family: Georgia, serif; */
    /* text-decoration: underline; */
    /* border-bottom: 1px solid; */
    /* font-family: 'Courier New', monospace; */
    /* height: max(7vw,7em); */
    display:grid;
    grid-template-columns: 1fr 1fr;
    height: min(9em,12vw);
    max-height: max(12vw);
    /* width: 100%; */
    overflow: hidden;
    /* width: 9em; */
    /* padding-top: 0.8em; */
    /* padding-left: 1vw; */
    /* padding-top: 0.5vw; */
}

.pcmmh {
    height: min(6vw,6em);
}

.explore {
    text-align: center;
    /* height: max(8vw,4em);
    width: max(8vw,4em); */
    fill: #1f0970;
    stroke: #1f0970;
    /* padding-bottom: 1em; */
    /* margin-top: 0.1em; */
    height: min(6em,10vw);
    /* background-color: white; */
}

.explore:hover {
  background-color: #f2f2f2;
}

button#explore {
    background-color: transparent;
}

#docs, #aboutPC {
    position: fixed;
    display:grid;
    background-color: white;
    /* padding-left: 4vw; */
    /* padding-top: 4vw; */
    /* width: 100%; */
    /* height: 35vh; */
    opacity: 95%;
    /* max-height: 35vh; */
    /* top: min(6em,10vw); */
    top: min(8em,(10vw+1em));

    z-index: 10;
}

#aboutPC {
    display: grid;
    grid-template-columns: 1fr;
    /* width: 100%; */
    text-align: left;
    visibility: hidden;
}

#docs {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    visibility: visible;
    /* width: 100%; */
    padding-left: 1em;
    padding-right: 1em;
}

#docs>button {
    /* background-color: #242424;
    color: #242424; */
}

.docButton>ul>li>button {
    width: 100%;
    /* text-align: right; */
    background-color: white;
    font-weight: bold;
    padding: 2vw;
    font-size: max(0.8em,11pt);
}

.docContent {
    max-width: 100%;
    overflow: scroll;
    /* font-size: max(4em,10pt); */
}

#docContent>div>p {
    font-size: max(0.8em,11pt);
}

.docButton {
    font-size: max(0.8em,11pt);
    padding: 2vw;
    width: 70%;
}

#docContent>div {
    /* width: 75vw; */
}

.docs {
    /* position: fixed; */
    align-content: center;
    background-color: white;
    text-align: left;
    width: 100px;

}

.docButton>ul {
    list-style-type: none;
    text-align: left;
}

#docs>ul>button {
    width: 30vw;
}

.introduction, .feature {
    /* width: 90%; */
    /* display: block; */
}

.introduction>div>p, .textCols>ul>li>p {
    /* text-align: justify; */
    /* align-self: center; */
    /* width: 80%; */
    display:block;
    /* padding-left: 5em;
    padding-right: 5em; */
    padding-bottom: 1em;
    font-size: min(1em,11pt);
;
}

.introduction>.textCols {
    /* display:grid;
    grid-template-columns: 1fr 1fr; */
}

.textCols {
    padding-top: 1em;
    width: 80%;
}

.textCols>p {
    font-size: 1em;
}

.textCols>ul {
    /* width: 60%; */
    list-style-type: none;
    margin-left: -5%;
}

.textCols>ul>li {
    text-align: center;
    /* margin-bottom: 0.5em; */
}

.textCols>p#invite {
    font-size: 1em;
    font-weight: bold;
}

.map {
    /* display: flex; */
    /* grid-template-columns: 1fr 3.4fr; */
    outline-style: none;
    outline-color: #242424;
    outline-width: 0.5em;
    /* padding: 1em; */
    /* border-radius: 0.5em; */
    /* width: 100%; */
    margin: 0;
    /* top: min(11vw,100px); */
}

.controls {
    /* display: grid; */
    /* grid-template-columns: auto; */
    /* padding-top: 1vw; */
    /* display:inline-flex; */
    /* grid-template-columns: 8% 8% 50% 34%; */
}
.dials {
    align-self: right;
    /* float: right; */
    height: min(8em,120px);
    /* margin-right: 2em;
    margin-left: 2em; */
    display: grid;
    grid-template-columns: 20% 40% 20% 12%;
    /* font-family: 'Montserrat'; */
    /* font-size: min(1em,4vw); */
    padding-left: min(1em,4vw);
    /* font-size: max(0.7em,1.2vw); */
}

.boxTop {
    /* margin-right: 5px; */
    /* grid-area: boxTop; */
    /* padding-top: .5vw; */
    /* min-height: 70px; */
    /* min-width: 40vw; */
    text-align: center;
    /* padding-left: max(3vw,1em); */
    /* padding-left: min(5vw,1em); */
    /* margin-left: 10vw; */
    /* display: grid;
    grid-template-columns: 1fr; */
    /* width: 50%; */
    /* font-size: min(1em,11pt); */
    font-size: min(2.5vw,1em);
    /* border: solid; */
}

.radio {
    text-align: left;
    /* font-size: min(1em,11pt); */
    /* font-size: max(0.6em,1vw); */
    font-size: min(2.5vw,1em);
}

#input1, #input2, #input3 {
    accent-color: #2f7f34;
}

#startPintition {
    /* display: none; */
    visibility: hidden;
    /* width: 10vw; */
}

#startPintition.button {
    height: min(4em);
}

/* #startPintition>ul{
    alignt-text: left;
    list-style-type: none;
} */

#clear, #reset, #startPintition {
    /* visibility: hidden; */
    margin-top: 0.5em;
    /* margin-left: 15%;
    margin-top: 2%;
    margin-bottom: 2%; */
    width: min(6em,12vw);
    height: min(2em,6vw);
    /* padding-left: min(5px,5%);
    padding-right: min(5px,5%);
    padding-top: min(5px,5%);
    padding-bottom: min(5px,5%); */
    /* max-height: 3em; */
    background-color: #242424;
    font-size: min(2.5vw,0.8em);
}

#mapPin {
    visibility: hidden;
    width: 30%;
    /* position: absolute; */
    /* padding-left: 1.2%;
    padding-top: 0.8%; */
}

.mapPin {
    /* display:grid; */
}

.selectors {
    /* display: grid; */
    text-align: right;
    /* align-items: flex-end; */
    /* padding: 2%; */
    font-size: min(2.5vw,1em);
}

.selectors>selector {
    /* text-align: right;
    align-items: flex-end; */
    /* font-size: max(0.7em,1.4vw); */
}

.selectAgg {
    /* grid-area: selectAgg; */
    /* padding: 5px; */
    /* min-height: 6vw; */
    /* border-left: solid 2px; */
    display: grid;
}

.hoverData {
    display: grid;
    /* grid-template-columns: auto auto auto auto; */
    /* grid-template-columns: 1fr; */
    grid-template-areas:
        "boxLeft boxRight aggregate list";
    /* width: 90%; */
    /* position: sticky; */
    /* top:100px; */
    padding-left: min(1em,4vw);
    /* padding-top: min(1em,5vw); */
    padding-bottom: min(2em,5vw);
    /* max-width: 1080px; */
    /* font-size: max(0.7em,1.2vw); */
    grid-template-columns: 20% 40% 10% 30%;
    height: min(10em,120px);
}

.boxLeft {
    /* align-self: left; */
    /* align-self: right; */
    /* margin-right: 5px; */
    grid-area: boxLeft;
    /* padding-left: min(5vw,1em); */
    /* width: max(8em,6vw); */
    /* height: 20vw; */
    /* width: min(1em,11pt); */
    /* width: auto; */
    /* float: right; */
    text-align: center;
    /* font-size: min(1em,11pt); */
    /* font-size: min(1em,4vw); */
    /* border: solid; */
    font-size: min(2.5vw,1em);
}

.boxRight {
    /* align-items: start; */
    /* margin-right: 5px; */
    grid-area: boxRight;
    /* padding: 5px; */
    /* width: max(12em,175px); */
    /* min-width: 170px; */
    /* max-width: 200px; */
    float: right;
    text-align: center;
    font-size: min(2.5vw,1em);
    /* font-size: min(1em,11pt); */
    /* width:25vw; */
}

.aggregate {
    align-self: left;
    /* border-right: solid 2px; */
    /* padding-right: 5px; */
    /* border-left: solid 2px; */
    /* width: max(3em,6vw); */
    /* padding: 5px; */
    min-height: 50px;
    grid-area: aggregate;
    border-left: solid 2px;
    /* font-size: max(0.7em,1vw); */
    font-size: min(2.5vw,1em);
    /* font-size: min(1em,4vw); */
}

.list {
    grid-area: list;
    /* border: 1px double white; */
    /* padding: .75rem; */
    /* width: 25vw; */
    /* font-size: min(1em,11pt); */
    /* font-size: max(0.6em,1vw); */
    font-size: min(2.4vw,0.8em);
    /* width: 100%; */
    align-text: left;
    /* margin: 0; */
    /* width: 20em; */
    /* height: 10em; */
    /* overflow: auto; */
}

#state, #statePick, #nation, #feature {
    width: 100%;
}

#county-state {
    display: grid;
    grid-template-columns: 1fr;
    align-text:left;
    padding-top:1em;
    overflow-y: scroll;
    max-height: min(6em,14vw);
    list-style-type: none;
}

#county-state>li.counties {
    /* width:100%; */
    /* align-text:left; */
}

#county-state>li.states {
    /* width:50px; */
}

#shareBlock {
    /* border: solid; */
    /* float: right; */
    display: inline-block;
    /* padding-right: min(1vw,1em); */
    padding-top: min(1vw,1em);
}

input:checked {
     border: #232323;
     background-color: #232323;
}

#share {
  margin:auto;
}

.share-btn {
  /* display: flex;
  flex-flow: column wrap; */
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content:center;
  /* margin-left:auto; */
  /* margin-right:auto; */
  height: min(8vw,6em);
  width: min(8vw,6em);
  /* padding:1vw; */
  font-family: monospace;
  font-weight: bold;
  color: var(--color-complement-1a);
  background-color: var(--color-background-1);
  border:none;
  line-height:0;
  border-radius: 6vw;
  /* filter: drop-shadow( 0px 0px 3px var(--color-background-3)); */
}

.share-btn-active {
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content:center;
  margin-left:auto;
  margin-right:auto;
  /* width:12vw;
  height:12vw; */
  /* padding:1vw; */
  font-family: monospace;
  font-size: 3vw;
  font-weight: bold;
  color: var(--color-complement-1a);
  background-color: var(--color-background-1);
  border:none;
  border-radius: 7vw;
  filter: drop-shadow( 0px 0px 3px #1f0970);
}

.share-img {
  height: min(6vw,5em);
  width: min(6vw,5em);
  line-height: 0;
  /* background-color: white; */
}

.share-img:hover {
  filter: drop-shadow( 0px 0px 4px #242424);
}

#modal {
  /* display: grid; */
  /* justify-content: flex-end; */
  /* z-index: 1; */
  /* float: right; */
  /* justify-self: end; */
  /* grid-template-rows: 1fr 3fr 1fr; */
  /* grid-template-columns: repeat(5, 1fr); */
  /* grid-auto-columns:auto; */
  /* justify-items: end; */
  /* align-items: end; */
  /* background-color: #242424; */
  /* border-color: var(--color-complement-1a); */
  /* border-width: 10px; */
  /* position: fixed; */
  /* bottom: min(8em,8vw); */
  /* top: 50%;
  left: 50%; */
  /* right: 0.5em; */
  height: 12vw;
  max-height: max(8vw,80px);
  /* max-width: 500px; */
  /* left: 100vw; */
  /* height:36vw; */
  /* filter: drop-shadow( 0px 0px 3px var(--color-background-3)); */
}

.modal-title {
  font-size: 1em;
  font-style: italic;
  font-weight: bold;
}

.modal-header {
    display: grid;
    justify-items: center;
    align-items: center;
}

.modal-header>p {
    text-align: center;
    /* width: 100%; */
    font-weight: bold;
}

.modal-body {
  display:grid;
  grid-template-columns: auto auto auto auto;
  /* margin-right:1vw; */
  /* justify-items: stretch; */
  /* justify-content: flex-start; */
  /* position:relative; */
  /* align-items: baseline; */
  /* height: 8vw; */
  line-height: 0;
  /* background-color: var(--color-background-1); */
  vertical-align: text-top;
  height: min(8vw,6em);
  width: 100%;
  /* padding-left: 3vw;
  padding-right: 3vw; */
  /* max-width: max(20vw,100px); */
  filter: drop-shadow( 0px 0px 3px var(--color-background-3));
  /* padding-bottom: 0.5em; */
  justify-items: center;
  align-items: center;
  padding-left: 0vw;
}

.modal-footer {
  display:grid;
  /* justify-content: flex-end; */
  grid-template-columns: 70% 10%;
  font-size: 16pt;
  /* overflow: hidden; */
  /* height: 8vw; */
  /* background-color: var(--color-background-1); */
  max-height: 2em;
  width: 70%;
  /* max-width: max(12vw,100px); */
  padding-bottom: 0.5em;
  justify-items: center;
  align-items: center
}

.modal-icons {
  /* display: flex; */
  /* margin-right:0vw;
  margin-left:0vw; */
  /* padding:0.5vw; */
  /* height: min(6vw,4em); */
  /* width: min(6vw,4em); */
  /* padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 2vw; */
  /* width:25vw; */
  /* display:inline-flex; */
  /* width: 80px; */
}

#footer {
    height: min(6em,15vw);
    text-align: center;
    dominant-baseline: middle;
    /* font-size: 1em; */
    width: 100%;
    /* border: solid; */
    border-width: 0.2em;
    /* padding: 1em; */
    background-color: white;
    z-index: 10;
    display: grid;
    /* bottom: 0; */
}

#footer>p {
    font-size: min(0.7em,7pt);
}

p {
    padding: 0;
    /* font-size: min(1em,11pt); */
    /* font-size: min(1em,4vw); */
    margin: 0.2rem;
}

#pintition-form>#introText>p {
    /* font-size: 1em; */
    margin-left: 1em;
    margin-right: 1em;
}

.tabs {
    display:inline-flex;
}

#selectionPrompt {
    font-style: italic;
    text-align: left;
    padding-left: 20%;
}

#socialLinks {
    /* width: 30vw; */
    /* height: max(2vw,2em); */
    display: grid;
    grid-template-columns: auto auto auto auto;
}
#socialLinks>img {
    /* width: max(3.5vw,1em); */
    padding: 1vw;
    fill: #1f0970;
    stroke: #1f0970;
    
}

.socialLinks {
    fill: #1f0970;
    stroke: #1f0970;
    padding: 1vw;
    height: min(4vw,2em);
}

.rfscores, .cprovs, .pratio, .psgap { 
    display: none;
    text-align: justify;
}

.overview {
    display: block;
    text-align: justify;
}

.overview>h3 {
    text-align: left;
}

#verifyInstructions {
    display: none;
    position: fixed;
    background-color: #242424;
    /* height: 18%; */
    width: max(45%,260px);
    padding: 0.2em;
    left:0;
    top:67.5vh;
    color: white;
    border-radius: 0.5em;
}

#verifyInstructions>p {
    padding: 1em;
    padding-top: 0em;
}

#pintition-form, #pintition-info {
    display:none;
    position:fixed;
    /* float:right; */
    /* width:45vw; */
    /* height:42vh; */
    /* min-width:220px; */
    width: max(45%,260px);
    background-color: #242424;
    color: white;
    text-align: center;
    padding: 0.2em;
    margin-left: 0%;
    border-radius: 0.5em;
    /* opacity: 90%; */
    /* top: min(11vw,100px); */
    left:0%;
    bottom:32.5vh;
    height: max(40%,27em);
    /* border-radius: 1em; */
    /* font-size: 1em; */
}

#pintition-form>p {
    /* text-align: left; */
}

#pintition-form>input {
    /* font-size: 1em; */
}

#pintition-form>ul {
    /* text-align: left; */
}

#urlCopy {
    height: min(1.6em,5vw);
    font-size: min(0.6em,4vw);
    width: 6em;
}

#verifySubmit, #formSubmit {
    margin-top: 0vw;
    width: max(5vw,80%);
    display: grid;

    /* justify-content: space-between; */
}

#fn, #mn, #ln, #em {
    width: max(5vw,80%);
    height: 1.5em;
    margin-bottom: 0.2em;
}

#pintSubmit {
    width: max(5vw,80%);
    display: none;
}

#code {
    width: max(5vw,60%);
    visibility: hidden;
    margin-top: 1em;
    text-align: center;
}

#introText {
    margin-top: 1em;
    width: max(5em, 100%);
    margin-bottom: 0.5em;
}

#pintClose {
    margin-top: 1em;
    margin-bottom: 0vw;
    width: max(5em, 25%);
    border: solid 0.2em white;
    background-color: white;
}

#pintSubmit, #pintSign, #pintInfo, #verify, #cancel {
    /* color: white; */
    /* height: max(2%,2em); */
    margin-top: 1em;
    margin-bottom: 0vw;
    width: max(5em, 80%);
    border: solid 0.2em white;
    /* width: max(5vw,80%); */
    background-color: white;
}

.choropleth {
    padding: .5em;
}

.indicator {
    width: auto;
}

.county:hover {
    fill-opacity: 80%;
}

.county:active {
    fill-opacity: 50%;
}

.clickList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 3fr;
    grid-template-areas:
        "selectAgg selectAgg selectAgg"
        "aggregate list list";
    /* width: 50%; */
}

/* button {
    background-color: transparent;
    padding: 15px 32px;
    margin: 5px;
    color: var(--color-text);
    width: 15vw;
    text-align: left;
    margin-left: 0rem;
    font-size: min(0.7em,7pt);
    border: none;
} */

/* button:active {
    background-color: green;
} */

select {
    /* font-size: min(1em,11pt); */
    font-size: min(1em,4vw);
}


#buttons {
    display: flex;
    width: 80%;
    /* grid-template-columns: auto auto; */
}

.button {
    border-radius: 8px;
    background-color: #242424;
    opacity: 90%;
    border: none;
    color: white;
    /* margin-left: 1.2em; */
    /* margin: 5px; */
    /* width: 400px; */
    text-align: center;
    text-decoration: none;
    height: 5vw;
    background-color: white;
    /* display: inline-block; */
    /* font-size: min(1em,11pt); */
    /* font-size: min(1em,4vw); */
}

.fadingEffect {
        -moz-animation: showHide 1s ease-in ; /* Firefox */
        -webkit-animation: showHide 1s ease-in ; /* Safari and Chrome */
        -ms-animation: showHide 1s ease-in ; /* IE10 */
        -o-animation: showHide 1s ease-in ; /* Opera */
        animation: showHide 1s ease-in ;
    }
    @-webkit-keyframes showHide { /* Chrome, Safari */
        0% {opacity:0%}
        40% {opacity:40%}
        60% {opacity:60%;}
        100% {opacity:100%;}
    }
    @-moz-keyframes showHide { /* FF */
        0% {opacity:0%}
        40% {opacity:40%}
        60% {opacity:60%;}
        100% {opacity:100%;}
    }
    @-ms-keyframes showHide { /* IE10 */
        0% {opacity:0%}
        40% {opacity:40%}
        60% {opacity:60%;}
        100% {opacity:100%;}
    }
    @-o-keyframes showHide { /* Opera */
        0% {opacity:0%}
        40% {opacity:40%}
        60% {opacity:60%;}
        100% {opacity:100%;}
    }
    @keyframes showHide {
        0% {opacity:0%}
        40% {opacity:40%}
        60% {opacity:60%;}
        100% {opacity:100%;}
    }

    @-webkit-keyframes initLoad{
        0%{opacity: 0;}
        25%{opacity: .25;}
        50%{opacity: .50;}
        75%{opacity: .75;}
        100%{opacity: 1;}
    }


    @keyframes initLoad{
        0%{opacity: 0;}
        25%{opacity: .25;}
        50%{opacity: .50;}
        75%{opacity: .75;}
        100%{opacity: 1;}
    }

</style>